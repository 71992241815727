import { jsPDF } from "jspdf";
import { EraDto } from "src/app/services/generatedApi";

export const generateJobInfo = (doc: jsPDF, eraDto: EraDto) => {
  const jobInfoHead = [
    [
      { content: "Job Name:", colSpan: 1 },
      { content: eraDto.jobInfo.jobName || "", colSpan: 1 },
      { content: "Dept:", colSpan: 1 },
      { content: eraDto.jobInfo.department || "", colSpan: 1 },
      { content: "Assessor:", colSpan: 1 },
      { content: eraDto.jobInfo.assessor || "", colSpan: 1 },
      { content: "Date:", colSpan: 1 },
      {
        content: eraDto.jobInfo.date
          ? new Date(eraDto.jobInfo.date).toLocaleDateString()
          : "",
        colSpan: 1,
      },
    ],
    [
      { content: "Shift:", colSpan: 2 },
      { content: eraDto.jobInfo.shift || "", colSpan: 2 },
      { content: "Cycle Time:", colSpan: 2 },
      { content: `${eraDto.jobInfo.cycleTime || ""} sec`, colSpan: 2 },
    ],
  ];

  doc.autoTable({
    startY: 22,
    head: jobInfoHead,
    body: [],
    theme: "plain",
    styles: {
      cellPadding: 2,
      fontSize: 10,
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
    },
  });

  doc.text(
    "Step 1: Identify risk factors...",
    10,
    doc.lastAutoTable.finalY + 10,
  );

  return doc;
};
