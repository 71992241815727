import back_flex_extend from "src/assets/images/era/back_flex_extend.jpg";
import back_sideways from "src/assets/images/era/back_sideways.jpg";
import back_twisted from "src/assets/images/era/back_twisted.jpg";
import lowerarm_flex from "src/assets/images/era/lowerarm_flex.jpg";
import lowerarm_rotate from "src/assets/images/era/lowerarm_rotate.jpg";
import neck_flex_extend from "src/assets/images/era/neck_flex_extend.jpg";
import neck_sideways from "src/assets/images/era/neck_sideways.jpg";
import neck_twist from "src/assets/images/era/neck_twist.jpg";
import upperarm_abduct from "src/assets/images/era/upperarm_abduct.jpg";
import upperarm_flex_extend from "src/assets/images/era/upperarm_flex_extend.jpg";
import wrist_flex_extend from "src/assets/images/era/wrist_flex_extend.jpg";
import wrist_radial_ulnar from "src/assets/images/era/wrist_radial_ulnar.jpg";

export interface BodyPartConfig {
  title: string;
  images: {
    src: string;
    alt: string;
  }[];
  sections: {
    [key: string]: {
      title: string;
      labels?: { [key: string]: string };
    };
  };
  bilateral?: boolean;
}

export const bodyPartConfig: { [key: string]: BodyPartConfig } = {
  back: {
    title: "Back Assessment",
    images: [
      {
        src: back_flex_extend,
        alt: "Back Flexion/Extension",
      },
      { src: back_sideways, alt: "Back Side Bending" },
      { src: back_twisted, alt: "Back Twisting" },
    ],
    sections: {
      posture: {
        title: "Posture",
        labels: {
          flexedOver20: "Flexed > 20°",
          extended: "Extended",
          sidewaysOver20: "Sideways > 20°",
          twistedOver20: "Twisted > 20°",
        },
      },
      severePosture: {
        title: "Severe Posture",
        labels: {
          flexedOver45: "Flexed > 45°",
          extendedOver20: "Extended > 20°",
        },
      },
      force: {
        title: "Force",
        labels: {
          over10lb: "Force > 10 lb",
        },
      },
      repetition: {
        title: "Repetition",
        labels: {
          over2PerMin: "Movement > 2 per minute",
        },
      },
      staticExertion: {
        title: "Static Exertion",
        labels: {
          over10Sec: "Held > 10 seconds",
        },
      },
    },
  },
  neck: {
    title: "Neck Assessment",
    images: [
      {
        src: neck_flex_extend,
        alt: "Neck Flexion/Extension",
      },
      { src: neck_sideways, alt: "Neck Side Bending" },
      { src: neck_twist, alt: "Neck Twisting" },
    ],
    sections: {
      posture: {
        title: "Posture",
        labels: {
          flexedOver30: "Flexed > 30°",
          extended: "Extended",
          sideways: "Sideways",
          twisted: "Twisted",
        },
      },
      severePosture: {
        title: "Severe Posture",
        labels: {
          flexedOver60: "Flexed > 60°",
          extendedOver30: "Extended > 30°",
        },
      },
      repetition: {
        title: "Repetition",
        labels: {
          over3PerMin: "Movement > 3 per minute",
        },
      },
      staticExertion: {
        title: "Static Exertion",
        labels: {
          over10Sec: "Held > 10 seconds",
        },
      },
    },
  },
  shoulder: {
    title: "Shoulder Assessment",
    images: [
      {
        src: upperarm_flex_extend,
        alt: "Shoulder Flexion/Extension",
      },
      { src: upperarm_abduct, alt: "Shoulder Abduction" },
    ],
    sections: {
      posture: {
        title: "Posture",
        labels: {
          flexedOver45: "Flexed > 45°",
          extended: "Extended",
          abductedOver45: "Abducted > 45°",
        },
      },
      severePosture: {
        title: "Severe Posture",
        labels: {
          flexedOver90: "Flexed > 90°",
          abductedOver90: "Abducted > 90°",
        },
      },
      force: {
        title: "Force",
        labels: {
          over10lb1Hand: "One-handed force > 10 lb",
          over15lb2Hand: "Two-handed force > 15 lb",
        },
      },
      repetition: {
        title: "Repetition",
        labels: {
          over2Point5PerMin: "Movement > 2.5 per minute",
        },
      },
      staticExertion: {
        title: "Static Exertion",
        labels: {
          over10Sec: "Held > 10 seconds",
        },
      },
    },
    bilateral: true,
  },
  elbow: {
    title: "Elbow Assessment",
    images: [
      { src: lowerarm_flex, alt: "Elbow Flexion" },
      { src: lowerarm_rotate, alt: "Forearm Rotation" },
    ],
    sections: {
      posture: {
        title: "Posture",
        labels: {
          flexedUnder60: "Flexed < 60°",
          flexedOver100: "Flexed > 100°",
          forearmRotated: "Forearm Rotated",
        },
      },
      severePosture: {
        title: "Severe Posture",
        labels: {
          forearmRotatedOver60: "Forearm Rotated > 60°",
        },
      },
      force: {
        title: "Force",
        labels: {
          over10lb1Hand: "One-handed force > 10 lb",
          over15lb2Hand: "Two-handed force > 15 lb",
        },
      },
      repetition: {
        title: "Repetition",
        labels: {
          over10PerMin: "Movement > 10 per minute",
        },
      },
      staticExertion: {
        title: "Static Exertion",
        labels: {
          over10Sec: "Held > 10 seconds",
        },
      },
    },
    bilateral: true,
  },
  wrist: {
    title: "Wrist Assessment",
    images: [
      {
        src: wrist_flex_extend,
        alt: "Wrist Flexion/Extension",
      },
      {
        src: wrist_radial_ulnar,
        alt: "Wrist Radial/Ulnar Deviation",
      },
    ],
    sections: {
      posture: {
        title: "Posture",
        labels: {
          flexedOver45: "Flexed > 45°",
          extendedOver45: "Extended > 45°",
          radialDeviatedOver15: "Radial Deviation > 15°",
          ulnarDeviatedOver20: "Ulnar Deviation > 20°",
        },
      },
      severePosture: {
        title: "Severe Posture",
        labels: {
          flexedOver65: "Flexed > 65°",
          extendedOver65: "Extended > 65°",
        },
      },
      force: {
        title: "Force",
        labels: {
          pinchGripOver2lb: "Pinch grip > 2 lb",
          powerGripOver10lb: "Power grip > 10 lb",
        },
      },
      repetition: {
        title: "Repetition",
        labels: {
          over15PerMin: "Movement > 15 per minute",
        },
      },
      staticExertion: {
        title: "Static Exertion",
        labels: {
          over10Sec: "Held > 10 seconds",
        },
      },
    },
    bilateral: true,
  },
};

export const sectionColors = {
  posture: {
    title: "#FED072",
    border: "#EFE8D1",
  },
  severePosture: {
    title: "#FF8A80",
    border: "#EFDBDE",
  },
  force: {
    title: "#444",
    border: "#eee",
  },
  repetition: {
    title: "#444",
    border: "#eee",
  },
  staticExertion: {
    title: "#444",
    border: "#eee",
  },
};
