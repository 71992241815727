import {
  Dangerous,
  ErrorOutline,
  GppGood,
  ReportProblem,
} from "@mui/icons-material";

export type EraScoreDetailType = {
  range: { min?: number; max?: number };
  detail: string;
  color: string;
  icon: any;
};

export const eraScoresDetail: EraScoreDetailType[] = [
  {
    range: { min: 0, max: 20 },
    detail: "Low risk, no action required",
    color: "#B2DFDB",
    icon: GppGood,
  },
  {
    range: { min: 21, max: 35 },
    detail: "Moderate risk, further investigation recommended",
    color: "#FFF176",
    icon: ErrorOutline,
  },
  {
    range: { min: 36, max: 55 },
    detail: "High risk, action strongly recommended",
    color: "#FFB74D",
    icon: ReportProblem,
  },
  {
    range: { min: 56 },
    detail: "Very high risk, immediate action required",
    color: "#B71C1C",
    icon: Dangerous,
  },
];

export const getEraScoresDetailItem = (score: number) => {
  let index = 0;
  for (; index < eraScoresDetail.length - 1; index++) {
    const max = eraScoresDetail[index].range.max;
    if (max === undefined || max >= score) {
      break;
    }
  }
  return eraScoresDetail[index];
};
