import { jsPDF } from "jspdf";

export const setupPdfDocument = () => {
  const doc = new jsPDF();

  // Add header
  doc.setFillColor(255, 0, 0);
  doc.rect(10, 10, 190, 10, "F");
  doc.setTextColor(255, 255, 255);
  doc.setFontSize(14);
  doc.text("ERGONOMIC RISK ASSESSMENT", 15, 17);

  // Reset text styling
  doc.setTextColor(0, 0, 0);
  doc.setFontSize(10);

  return doc;
};
