import { Button, Checkbox, Stack, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import { FC } from "react";
import { QuestionType } from "src/app/logic/assessment/reba-rula/types";

type SelectItemPropsType = {
  type: QuestionType;
  img?: string;
  video?: string;
  text?: string;
  isSelected?: boolean;
  flipImage?: boolean;
  onSelect: () => void;
};

const getButtonStyles = (
  isSelected: boolean,
  img?: string,
  type?: QuestionType,
  flipImage?: boolean,
) => ({
  textTransform: "none",
  borderRadius: 1,
  border: "1px solid #ccc",
  background: "white",
  color: green[400],
  overflow: "hidden",
  width: "100%",
  minHeight: "100px",
  ...(img && {
    backgroundImage: `url(${img})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    ...(flipImage && {
      transform: "scaleX(-1)",
    }),
  }),
  ...(isSelected && {
    border: "1px solid " + green[500],
    color: green[600],
    background: green[50],
    "&:hover": {
      backgroundColor: green[50],
      cursor: type === QuestionType.CHECK ? "pointer" : "default",
    },
  }),
  ...(!img && { p: 1 }),
});

export const SelectItem: FC<SelectItemPropsType> = ({
  type,
  img,
  video,
  text,
  isSelected = false,
  flipImage = false,
  onSelect,
}) => (
  <Button
    sx={getButtonStyles(isSelected, img, type, flipImage)}
    variant={isSelected ? "contained" : "outlined"}
    onClick={onSelect}
  >
    <Stack spacing={1} alignItems="center">
      {video && (
        <video
          autoPlay
          loop
          src={video}
          style={{
            maxWidth: "100%",
            transform: flipImage ? "scaleX(-1)" : "none",
          }}
        />
      )}
      {type === QuestionType.CHECK && !img && <Checkbox checked={isSelected} />}
      {!img && <Typography fontWeight={600}>{text}</Typography>}
    </Stack>
  </Button>
);
