import React, { FC, MouseEvent, Dispatch } from "react";
import {
  TableCell,
  TableRow,
  Checkbox,
  TableBody,
  IconButton,
  Chip,
} from "@mui/material";
import {
  stateType,
  tableOrderType,
} from "src/components/organisms/tasks/types";
import { blue, green, grey, red } from "@mui/material/colors";
import { MinimalTaskRo } from "src/app/services/generatedApi";
import moment from "moment";
import { Circle, Refresh } from "@mui/icons-material";
import { BodyPartState } from "src/context/TaskContext";
import { getStateColor } from "src/components/molecules/result/overallRiskScore/OverallRiskScore";
import { useAppSelector } from "src/app/hooks";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(
  order: tableOrderType,
  orderBy: keyof MinimalTaskRo,
): (a: MinimalTaskRo, b: MinimalTaskRo) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const statusColorHandler = (state: stateType) => {
  let color: string = grey[500];
  switch (state) {
    case stateType.READY:
      color = green[500];
      break;
    case stateType.PROCESSING:
      color = blue[500];
      break;
    case stateType.ERROR:
      color = red[500];
      break;
    default:
      color = grey[500];
      break;
  }
  return color;
};

type TasksTableBodyPropsType = {
  rows: MinimalTaskRo[];
  selected: string[];
  setSelected: Dispatch<React.SetStateAction<string[]>>;
  order: tableOrderType;
  orderBy: keyof MinimalTaskRo;
  onRowClick: (task: MinimalTaskRo) => void;
  isAllTasks?: boolean;
  onRefresh?: (taskId: string) => void;
};

const TasksTableBody: FC<TasksTableBodyPropsType> = ({
  rows,
  selected,
  setSelected,
  order,
  orderBy,
  onRowClick,
  isAllTasks,
  onRefresh,
}) => {
  const isAdmin = useAppSelector((state) => state.auth?.isAdmin);

  const checkboxClickHandler = (e: MouseEvent<unknown>, row: MinimalTaskRo) => {
    e.stopPropagation();
    const selectedIndex = selected.indexOf(row.id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  return (
    <TableBody>
      {rows
        .slice()
        .sort(getComparator(order, orderBy))
        .map((row: MinimalTaskRo, index: number) => {
          const {
            id,
            name,
            description,
            riskScore,
            createdAt,
            state,
            tag,
            creator,
            isDeleted,
          } = row;
          const isItemSelected = selected.includes(id);
          const labelId = `enhanced-table-checkbox-${id}`;

          const riskState = !riskScore
            ? BodyPartState.SAFE
            : riskScore > 66
              ? BodyPartState.HAZARD
              : riskScore > 33
                ? BodyPartState.CAUTION
                : BodyPartState.SAFE;

          const stateColor = getStateColor(riskState);

          return (
            <TableRow
              hover
              onClick={() => onRowClick(row)}
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={index}
              selected={isItemSelected}
              sx={{
                cursor: row.state === stateType.READY ? "pointer" : "default",
              }}
            >
              <TableCell
                padding="checkbox"
                onClick={(e) => checkboxClickHandler(e, row)}
              >
                <Checkbox
                  color="primary"
                  checked={isItemSelected}
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </TableCell>
              <TableCell
                align="left"
                component="th"
                id={labelId}
                scope="row"
                padding="none"
              >
                {name}
              </TableCell>
              {isAllTasks && (
                <TableCell align="center">{creator || "-"}</TableCell>
              )}
              <TableCell align="center">
                {riskScore === undefined || riskScore < 0 ? (
                  "-"
                ) : (
                  <Chip
                    icon={<Circle sx={{ fill: stateColor[300] }} />}
                    label={riskScore + "%"}
                    size="small"
                  />
                )}
              </TableCell>
              {isAllTasks && (
                <TableCell
                  align="center"
                  sx={{
                    color: isDeleted ? red[500] : undefined,
                    fontWeight: "bold",
                  }}
                >
                  {isDeleted ? "Yes" : "No"}
                </TableCell>
              )}
              {tag !== undefined && (
                <TableCell align="center">{tag?.name || "-"}</TableCell>
              )}
              <TableCell align="center">
                {moment(createdAt).format("YYYY/MM/DD")}
              </TableCell>
              <TableCell align={description ? "left" : "center"}>
                {description || "-"}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: statusColorHandler(state as stateType),
                }}
              >
                {stateType[state]}
                {isAdmin &&
                  stateType[state] !== stateType.PENDING &&
                  stateType[state] !== stateType.PROCESSING &&
                  onRefresh && (
                    <IconButton onClick={() => onRefresh(id)}>
                      <Refresh />
                    </IconButton>
                  )}
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};

export default TasksTableBody;
