import { EraDto, ResultRo } from "src/app/services/generatedApi";
import { defaultEraData } from "./constants";
import { Angle3DResultRo } from "src/types/pose";

export const calculateEraData = (
  result: ResultRo[],
  selectedAngles3D?: Angle3DResultRo[],
  isSited: boolean = false,
): EraDto => {
  const eraData: EraDto = { ...defaultEraData };

  if (!result.length) return eraData;

  result.forEach((frame, index) => {
    const angles3D = selectedAngles3D?.[index];

    if (frame.back !== undefined) {
      if (frame.back > 20) eraData.back.posture.flexedOver20 = true;
      if (frame.back < 0) eraData.back.posture.extended = true;

      if (frame.back > 45) eraData.back.severePosture.flexedOver45 = true;
      if (frame.back < -20) eraData.back.severePosture.extendedOver20 = true;

      if (angles3D?.back) {
        if (Math.abs(angles3D.back.Beta) > 20) {
          eraData.back.posture.sidewaysOver20 = true;
        }
        if (Math.abs(angles3D.back.Gamma) > 20) {
          eraData.back.posture.twistedOver20 = true;
        }
      }
    }

    if (frame.neck !== undefined) {
      if (frame.neck > 30) eraData.neck.posture.flexedOver30 = true;
      if (frame.neck < 0) eraData.neck.posture.extended = true;

      if (frame.neck > 60) eraData.neck.severePosture.flexedOver60 = true;
      if (frame.neck < -30) eraData.neck.severePosture.extendedOver30 = true;

      if (angles3D?.neck) {
        if (Math.abs(angles3D.neck.Beta) > 10) {
          eraData.neck.posture.sideways = true;
        }
        if (Math.abs(angles3D.neck.Gamma) > 10) {
          eraData.neck.posture.twisted = true;
        }
      }
    }

    if (frame.leftUpperArm !== undefined) {
      if (frame.leftUpperArm > 45)
        eraData.leftShoulder.posture.flexedOver45 = true;
      if (frame.leftUpperArm < 0) eraData.leftShoulder.posture.extended = true;

      if (frame.leftUpperArm > 90)
        eraData.leftShoulder.severePosture.flexedOver90 = true;

      if (angles3D?.leftUpperArm) {
        if (Math.abs(angles3D.leftUpperArm.Beta) > 45) {
          eraData.leftShoulder.posture.abductedOver45 = true;
        }
        if (Math.abs(angles3D.leftUpperArm.Beta) > 90) {
          eraData.leftShoulder.severePosture.abductedOver90 = true;
        }
      }
    }

    if (frame.rightUpperArm !== undefined) {
      if (frame.rightUpperArm > 45)
        eraData.rightShoulder.posture.flexedOver45 = true;
      if (frame.rightUpperArm < 0)
        eraData.rightShoulder.posture.extended = true;
      if (frame.rightUpperArm > 90)
        eraData.rightShoulder.severePosture.flexedOver90 = true;

      if (angles3D?.rightUpperArm) {
        if (Math.abs(angles3D.rightUpperArm.Beta) > 45) {
          eraData.rightShoulder.posture.abductedOver45 = true;
        }
        if (Math.abs(angles3D.rightUpperArm.Beta) > 90) {
          eraData.rightShoulder.severePosture.abductedOver90 = true;
        }
      }
    }

    if (frame.leftLowerArm !== undefined) {
      if (frame.leftLowerArm < 60)
        eraData.leftElbow.posture.flexedUnder60 = true;
      if (frame.leftLowerArm > 100)
        eraData.leftElbow.posture.flexedOver100 = true;

      if (angles3D?.leftWrist?.Gamma) {
        if (Math.abs(angles3D.leftWrist.Gamma) > 45) {
          eraData.leftElbow.posture.forearmRotated = true;
        }
        if (Math.abs(angles3D.leftWrist.Gamma) > 60) {
          eraData.leftElbow.severePosture.forearmRotatedOver60 = true;
        }
      }
    }

    if (frame.rightLowerArm !== undefined) {
      if (frame.rightLowerArm < 60)
        eraData.rightElbow.posture.flexedUnder60 = true;
      if (frame.rightLowerArm > 100)
        eraData.rightElbow.posture.flexedOver100 = true;

      if (angles3D?.rightWrist?.Gamma) {
        if (Math.abs(angles3D.rightWrist.Gamma) > 45) {
          eraData.rightElbow.posture.forearmRotated = true;
        }
        if (Math.abs(angles3D.rightWrist.Gamma) > 60) {
          eraData.rightElbow.severePosture.forearmRotatedOver60 = true;
        }
      }
    }

    if (frame.leftWrist !== undefined) {
      if (frame.leftWrist > 45) eraData.leftWrist.posture.flexedOver45 = true;
      if (frame.leftWrist < -45)
        eraData.leftWrist.posture.extendedOver45 = true;

      if (frame.leftWrist > 65)
        eraData.leftWrist.severePosture.flexedOver65 = true;
      if (frame.leftWrist < -65)
        eraData.leftWrist.severePosture.extendedOver65 = true;

      if (angles3D?.leftWrist) {
        if (angles3D.leftWrist.Beta > 15) {
          eraData.leftWrist.posture.radialDeviatedOver15 = true;
        }
        if (angles3D.leftWrist.Beta < -20) {
          eraData.leftWrist.posture.ulnarDeviatedOver20 = true;
        }
      }
    }

    if (frame.rightWrist !== undefined) {
      if (frame.rightWrist > 45) eraData.rightWrist.posture.flexedOver45 = true;
      if (frame.rightWrist < -45)
        eraData.rightWrist.posture.extendedOver45 = true;
      if (frame.rightWrist > 65)
        eraData.rightWrist.severePosture.flexedOver65 = true;
      if (frame.rightWrist < -65)
        eraData.rightWrist.severePosture.extendedOver65 = true;

      if (angles3D?.rightWrist) {
        if (angles3D.rightWrist.Beta > 15) {
          eraData.rightWrist.posture.radialDeviatedOver15 = true;
        }
        if (angles3D.rightWrist.Beta < -20) {
          eraData.rightWrist.posture.ulnarDeviatedOver20 = true;
        }
      }
    }
  });

  return eraData;
};
