import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Checkbox,
  TextField,
  FormControlLabel,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Theme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import { AcceleratorDto } from "src/app/services/generatedApi";

interface AcceleratorsProps {
  accelerators: AcceleratorDto;
  handleUpdate: (path: string[], key: string, value: any) => void;
}

const StyledCard = styled(Card)(({ theme }: { theme: Theme }) => ({
  height: "100%",
  transition: "all 0.2s ease-in-out",
}));

const StyledCardContent = styled(CardContent)(
  ({ theme }: { theme: Theme }) => ({
    height: "100%",
    padding: theme.spacing(2),
    "&:last-child": {
      paddingBottom: theme.spacing(2),
    },
  }),
);

const bodyParts = [
  "Back",
  "Neck",
  "Left Shoulder",
  "Right Shoulder",
  "Left Elbow",
  "Right Elbow",
  "Left Wrist",
  "Right Wrist",
];

const acceleratorTypes = [
  { key: "vibration" as keyof AcceleratorDto, label: "Vibration" },
  { key: "contactStress" as keyof AcceleratorDto, label: "Contact Stress" },
  { key: "impactStress" as keyof AcceleratorDto, label: "Impact Stress" },
];

export const Accelerators: React.FC<AcceleratorsProps> = ({
  accelerators,
  handleUpdate,
}) => {
  const handleAcceleratorChange = (
    key: keyof AcceleratorDto,
    checked: boolean,
  ) => {
    handleUpdate(
      ["accelerators"],
      key,
      checked ? { bodyPartAffected: "", task: "" } : undefined,
    );
  };

  const handleFieldChange = (
    key: keyof AcceleratorDto,
    field: "bodyPartAffected" | "task",
    value: string,
  ) => {
    handleUpdate(["accelerators", key], field, value);
  };

  return (
    <Grid item xs={12}>
      <Typography variant="h5" gutterBottom>
        Accelerators
      </Typography>
      <Grid container spacing={2} alignItems="start">
        {acceleratorTypes.map(({ key, label }) => {
          const isSelected = !!accelerators[key];
          return (
            <Grid item xs={12} md={4} key={key}>
              <StyledCard
                variant="outlined"
                sx={{
                  border: `1px solid ${isSelected ? green[500] : "#ccc"}`,
                  backgroundColor: isSelected ? green[50] : "white",
                  "&:hover": {
                    boxShadow: isSelected
                      ? undefined
                      : "0 2px 4px rgba(0,0,0,0.1)",
                  },
                }}
              >
                <StyledCardContent>
                  <Box sx={{ mb: 2 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isSelected}
                          onChange={(e) =>
                            handleAcceleratorChange(key, e.target.checked)
                          }
                          name={key}
                          color="success"
                          sx={{
                            color: isSelected ? green[600] : undefined,
                            "&.Mui-checked": {
                              color: green[600],
                            },
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant="subtitle1"
                          sx={{ color: isSelected ? green[600] : "inherit" }}
                        >
                          {label}
                        </Typography>
                      }
                    />
                  </Box>

                  {isSelected && (
                    <Box>
                      <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                        <InputLabel
                          id={`${key}-body-part-label`}
                          sx={{ color: isSelected ? green[600] : "inherit" }}
                        >
                          Body Part Affected
                        </InputLabel>
                        <Select
                          labelId={`${key}-body-part-label`}
                          value={accelerators[key]?.bodyPartAffected || ""}
                          onChange={(e) =>
                            handleFieldChange(
                              key,
                              "bodyPartAffected",
                              e.target.value,
                            )
                          }
                          label="Body Part Affected"
                          sx={{
                            "& .MuiSelect-icon": {
                              color: isSelected ? green[600] : "inherit",
                            },
                          }}
                        >
                          {bodyParts.map((part) => (
                            <MenuItem key={part} value={part}>
                              {part}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <TextField
                        fullWidth
                        size="small"
                        label="Task Description"
                        value={accelerators[key]?.task || ""}
                        onChange={(e) =>
                          handleFieldChange(key, "task", e.target.value)
                        }
                        multiline
                        rows={2}
                        InputLabelProps={{
                          sx: { color: isSelected ? green[600] : "inherit" },
                        }}
                      />
                    </Box>
                  )}
                </StyledCardContent>
              </StyledCard>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
