import { jsPDF } from "jspdf";
import { EraDto } from "src/app/services/generatedApi";

export const generateMainTable = (doc: jsPDF, eraDto: EraDto) => {
  doc.setFontSize(10);
  doc.text(
    "Step 1: Identify risk factors, score each body part, and calculate individual body part scores:",
    10,
    doc.lastAutoTable.finalY + 10,
  );

  const mainTableHead = [
    [
      "",
      { content: "Back", colSpan: 2 },
      { content: "Neck", colSpan: 2 },
      "Shoulder",
      "L",
      "R",
      "Elbow",
      "L",
      "R",
      "Wrist",
      "L",
      "R",
    ],
  ];

  const mainTableBody = [
    [
      {
        content: "Posture",
        styles: { cellWidth: 16 },
      },
      {
        content: `Flexed>20°\nExtended\nSideways>20°\nTwisted>20°`,
        styles: { cellWidth: 22 },
      },
      {
        content: `${eraDto.back.posture.flexedOver20 ? "1" : "-"}\n${eraDto.back.posture.extended ? "1" : "-"}\n${eraDto.back.posture.sidewaysOver20 ? "1" : "-"}\n${eraDto.back.posture.twistedOver20 ? "1" : "-"}`,
        styles: { cellWidth: 5 },
      },
      {
        content: `Flexed>30°\nExtended\nSideways\nTwisted`,
        styles: { cellWidth: 22 },
      },
      {
        content: `${eraDto.neck.posture.flexedOver30 ? "1" : "-"}\n${eraDto.neck.posture.extended ? "1" : "-"}\n${eraDto.neck.posture.sideways ? "1" : "-"}\n${eraDto.neck.posture.twisted ? "1" : "-"}`,
        styles: { cellWidth: 5 },
      },
      {
        content: `Flexed>45°\nExtended\nAbducted>45°`,
        styles: { cellWidth: 22 },
      },
      {
        content: `${eraDto.leftShoulder.posture.flexedOver45 ? "1" : "-"}\n${eraDto.leftShoulder.posture.extended ? "1" : "-"}\n${eraDto.leftShoulder.posture.abductedOver45 ? "1" : "-"}`,
        styles: { cellWidth: 5 },
      },
      {
        content: `${eraDto.rightShoulder.posture.flexedOver45 ? "1" : "-"}\n${eraDto.rightShoulder.posture.extended ? "1" : "-"}\n${eraDto.rightShoulder.posture.abductedOver45 ? "1" : "-"}`,
        styles: { cellWidth: 5 },
      },
      {
        content: `Flexed<60°\nFlexed>100°\nForearm Rotated`,
        styles: { cellWidth: 28 },
      },
      {
        content: `${eraDto.leftElbow.posture.flexedUnder60 ? "1" : "-"}\n${eraDto.leftElbow.posture.flexedOver100 ? "1" : "-"}\n${eraDto.leftElbow.posture.forearmRotated ? "1" : "-"}`,
        styles: { cellWidth: 5 },
      },
      {
        content: `${eraDto.rightElbow.posture.flexedUnder60 ? "1" : "-"}\n${eraDto.rightElbow.posture.flexedOver100 ? "1" : "-"}\n${eraDto.rightElbow.posture.forearmRotated ? "1" : "-"}`,
        styles: { cellWidth: 5 },
      },
      {
        content: `Flexed>45°\nExtended>45°\nRadial Deviated>15°\nUlnar Deviated>20°`,
        styles: { cellWidth: 32 },
      },
      {
        content: `${eraDto.leftWrist.posture.flexedOver45 ? "1" : "-"}\n${eraDto.leftWrist.posture.extendedOver45 ? "1" : "-"}\n${eraDto.leftWrist.posture.radialDeviatedOver15 ? "1" : "-"}\n${eraDto.leftWrist.posture.ulnarDeviatedOver20 ? "1" : "-"}`,
        styles: { cellWidth: 5 },
      },
      {
        content: `${eraDto.rightWrist.posture.flexedOver45 ? "1" : "-"}\n${eraDto.rightWrist.posture.extendedOver45 ? "1" : "-"}\n${eraDto.rightWrist.posture.radialDeviatedOver15 ? "1" : "-"}\n${eraDto.rightWrist.posture.ulnarDeviatedOver20 ? "1" : "-"}`,
        styles: { cellWidth: 5 },
      },
    ],

    [
      "Severe\nPosture",
      {
        content: `Flexed>45°\nExtended>20°`,
      },
      {
        content: `${eraDto.back.severePosture.flexedOver45 ? "1" : "-"}\n${eraDto.back.severePosture.extendedOver20 ? "1" : "-"}`,
      },
      {
        content: `Flexed>60°\nExtended>30°`,
      },
      {
        content: `${eraDto.neck.severePosture.flexedOver60 ? "1" : "-"}\n${eraDto.neck.severePosture.extendedOver30 ? "1" : "-"}`,
      },
      {
        content: `Flexed>90°\nAbducted>90°`,
      },
      {
        content: `${eraDto.leftShoulder.severePosture.flexedOver90 ? "1" : "-"}\n${eraDto.leftShoulder.severePosture.abductedOver90 ? "1" : "-"}`,
      },
      {
        content: `${eraDto.rightShoulder.severePosture.flexedOver90 ? "1" : "-"}\n${eraDto.rightShoulder.severePosture.abductedOver90 ? "1" : "-"}`,
      },
      {
        content: "Forearm\nRotated>60°",
      },
      {
        content: `${eraDto.leftElbow.severePosture.forearmRotatedOver60 ? "1" : "-"}`,
      },
      {
        content: `${eraDto.rightElbow.severePosture.forearmRotatedOver60 ? "1" : "-"}`,
      },
      {
        content: `Flexed>65°\nExtended>65°`,
      },
      {
        content: `${eraDto.leftWrist.severePosture.flexedOver65 ? "1" : "-"}\n${eraDto.leftWrist.severePosture.extendedOver65 ? "1" : "-"}`,
      },
      {
        content: `${eraDto.rightWrist.severePosture.flexedOver65 ? "1" : "-"}\n${eraDto.rightWrist.severePosture.extendedOver65 ? "1" : "-"}`,
      },
    ],

    [
      "Force",
      {
        content: ">10 lb",
      },
      {
        content: `${eraDto.back.force.over10lb ? "2" : "-"}`,
      },
      {
        content: "N/A",
      },
      {
        content: "-",
      },
      {
        content: ">10 lb 1-hand\n>15 lb 2-hand",
      },
      {
        content: `${eraDto.leftShoulder.force.over10lb1Hand ? "2" : "-"}\n${eraDto.leftShoulder.force.over15lb2Hand ? "2" : "-"}`,
      },
      {
        content: `${eraDto.rightShoulder.force.over10lb1Hand ? "2" : "-"}\n${eraDto.rightShoulder.force.over15lb2Hand ? "2" : "-"}`,
      },
      {
        content: ">10 lb 1-hand\n>15 lb 2-hand",
      },
      {
        content: `${eraDto.leftElbow.force.over10lb1Hand ? "2" : "-"}\n${eraDto.leftElbow.force.over15lb2Hand ? "2" : "-"}`,
      },
      {
        content: `${eraDto.rightElbow.force.over10lb1Hand ? "2" : "-"}\n${eraDto.rightElbow.force.over15lb2Hand ? "2" : "-"}`,
      },
      {
        content: "Pinch Grip>2 lb\nPower Grip>10 lb",
      },
      {
        content: `${eraDto.leftWrist.force.pinchGripOver2lb ? "2" : "-"}\n${eraDto.leftWrist.force.powerGripOver10lb ? "2" : "-"}`,
      },
      {
        content: `${eraDto.rightWrist.force.pinchGripOver2lb ? "2" : "-"}\n${eraDto.rightWrist.force.powerGripOver10lb ? "2" : "-"}`,
      },
    ],

    [
      "Repetition",
      {
        content: ">2/min",
      },
      {
        content: `${eraDto.back.repetition.over2PerMin ? "2" : "-"}`,
      },
      {
        content: ">3/min",
      },
      {
        content: `${eraDto.neck.repetition.over3PerMin ? "2" : "-"}`,
      },
      {
        content: ">2.5/min",
      },
      {
        content: `${eraDto.leftShoulder.repetition.over2Point5PerMin ? "2" : "-"}`,
      },
      {
        content: `${eraDto.rightShoulder.repetition.over2Point5PerMin ? "2" : "-"}`,
      },
      {
        content: ">10/min",
      },
      {
        content: `${eraDto.leftElbow.repetition.over10PerMin ? "2" : "-"}`,
      },
      {
        content: `${eraDto.rightElbow.repetition.over10PerMin ? "2" : "-"}`,
      },
      {
        content: ">15/min",
      },
      {
        content: `${eraDto.leftWrist.repetition.over15PerMin ? "2" : "-"}`,
      },
      {
        content: `${eraDto.rightWrist.repetition.over15PerMin ? "2" : "-"}`,
      },
    ],

    [
      "Static\nExertion",
      {
        content: ">10 sec",
      },
      {
        content: `${eraDto.back.staticExertion.over10Sec ? "1" : "-"}`,
      },
      {
        content: ">10 sec",
      },
      {
        content: `${eraDto.neck.staticExertion.over10Sec ? "1" : "-"}`,
      },
      {
        content: ">10 sec",
      },
      {
        content: `${eraDto.leftShoulder.staticExertion.over10Sec ? "1" : "-"}`,
      },
      {
        content: `${eraDto.rightShoulder.staticExertion.over10Sec ? "1" : "-"}`,
      },
      {
        content: ">10 sec",
      },
      {
        content: `${eraDto.leftElbow.staticExertion.over10Sec ? "1" : "-"}`,
      },
      {
        content: `${eraDto.rightElbow.staticExertion.over10Sec ? "1" : "-"}`,
      },
      {
        content: ">10 sec",
      },
      {
        content: `${eraDto.leftWrist.staticExertion.over10Sec ? "1" : "-"}`,
      },
      {
        content: `${eraDto.rightWrist.staticExertion.over10Sec ? "1" : "-"}`,
      },
    ],

    [
      "TOTAL",
      { content: "" },
      {
        content: Object.values(eraDto.back.posture)
          .concat(Object.values(eraDto.back.severePosture))
          .concat(Object.values(eraDto.back.force))
          .concat(Object.values(eraDto.back.repetition))
          .concat(Object.values(eraDto.back.staticExertion))
          .filter(Boolean)
          .length.toString(),
      },
      { content: "" },
      {
        content: Object.values(eraDto.neck.posture)
          .concat(Object.values(eraDto.neck.severePosture))
          .concat(Object.values(eraDto.neck.repetition))
          .concat(Object.values(eraDto.neck.staticExertion))
          .filter(Boolean)
          .length.toString(),
      },
      { content: "" },
      {
        content: Object.values(eraDto.leftShoulder.posture)
          .concat(Object.values(eraDto.leftShoulder.severePosture))
          .concat(Object.values(eraDto.leftShoulder.force))
          .concat(Object.values(eraDto.leftShoulder.repetition))
          .concat(Object.values(eraDto.leftShoulder.staticExertion))
          .filter(Boolean)
          .length.toString(),
      },
      {
        content: Object.values(eraDto.rightShoulder.posture)
          .concat(Object.values(eraDto.rightShoulder.severePosture))
          .concat(Object.values(eraDto.rightShoulder.force))
          .concat(Object.values(eraDto.rightShoulder.repetition))
          .concat(Object.values(eraDto.rightShoulder.staticExertion))
          .filter(Boolean)
          .length.toString(),
      },
      { content: "" },
      {
        content: Object.values(eraDto.leftElbow.posture)
          .concat(Object.values(eraDto.leftElbow.severePosture))
          .concat(Object.values(eraDto.leftElbow.force))
          .concat(Object.values(eraDto.leftElbow.repetition))
          .concat(Object.values(eraDto.leftElbow.staticExertion))
          .filter(Boolean)
          .length.toString(),
      },
      {
        content: Object.values(eraDto.rightElbow.posture)
          .concat(Object.values(eraDto.rightElbow.severePosture))
          .concat(Object.values(eraDto.rightElbow.force))
          .concat(Object.values(eraDto.rightElbow.repetition))
          .concat(Object.values(eraDto.rightElbow.staticExertion))
          .filter(Boolean)
          .length.toString(),
      },
      { content: "" },
      {
        content: Object.values(eraDto.leftWrist.posture)
          .concat(Object.values(eraDto.leftWrist.severePosture))
          .concat(Object.values(eraDto.leftWrist.force))
          .concat(Object.values(eraDto.leftWrist.repetition))
          .concat(Object.values(eraDto.leftWrist.staticExertion))
          .filter(Boolean)
          .length.toString(),
      },
      {
        content: Object.values(eraDto.rightWrist.posture)
          .concat(Object.values(eraDto.rightWrist.severePosture))
          .concat(Object.values(eraDto.rightWrist.force))
          .concat(Object.values(eraDto.rightWrist.repetition))
          .concat(Object.values(eraDto.rightWrist.staticExertion))
          .filter(Boolean)
          .length.toString(),
      },
    ],
  ];

  doc.autoTable({
    startY: doc.lastAutoTable.finalY + 15,
    head: mainTableHead,
    body: mainTableBody,
    theme: "grid",
    styles: {
      cellPadding: 2,
      fontSize: 8,
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
      valign: "middle",
    },
    headStyles: {
      fillColor: [220, 220, 220],
      textColor: [0, 0, 0],
      fontStyle: "bold",
      halign: "center",
    },
  });

  return doc;
};
