import { RulaDto, ResultRo } from "src/app/services/generatedApi";
import { getRulaQuestions } from "./RulaQuestions";
import { RulaItems, RulaQuestions } from "./types";
import {
  getIdOfSelectedItem,
  getValueOfRulaQuestions,
  setIndexById,
} from "../reba-rula/helper";
import { Angle3DResultRo } from "src/types/pose";
import { calculateOrganAngle } from "../utils";

export const extractRulaData = (
  rulaQuestions: RulaQuestions,
  result: ResultRo,
  selectedAngles3D?: Angle3DResultRo,
  isSited: boolean = false,
): RulaQuestions => {
  const upperArmAngle = calculateOrganAngle(
    selectedAngles3D?.leftUpperArm?.Beta || result.leftUpperArm,
    selectedAngles3D?.rightUpperArm?.Beta || result.rightUpperArm,
  );
  const lowerArmAngle = calculateOrganAngle(
    result.leftLowerArm,
    result.rightLowerArm,
  );
  const wristAngle = calculateOrganAngle(result.leftWrist, result.rightWrist);

  rulaQuestions[RulaItems.UPPER_ARM_ANGLE].update(upperArmAngle);
  rulaQuestions[RulaItems.LOWER_ARM_ANGLE].update(lowerArmAngle);
  rulaQuestions[RulaItems.WRIST_ANGLE].update(wristAngle);
  rulaQuestions[RulaItems.NECK_ANGLE].update(result.neck);
  rulaQuestions[RulaItems.TRUNK_ANGLE].update(
    result.back,
    isSited,
    selectedAngles3D?.back,
  );
  rulaQuestions[RulaItems.TRUNK_ADJUST].update?.(selectedAngles3D?.back);

  return rulaQuestions;
};

export const getRulaDataFromResult = (rulaResult: RulaDto): RulaQuestions => {
  const rulaQuestions = getRulaQuestions();
  setIndexById(
    rulaQuestions[RulaItems.UPPER_ARM_ANGLE],
    rulaResult.upperArmAngle,
  );
  rulaQuestions[RulaItems.UPPER_ARM_ADJUST].items[0].selected =
    rulaResult.upperArmAdjust.raisedShoulder;
  rulaQuestions[RulaItems.UPPER_ARM_ADJUST].items[1].selected =
    rulaResult.upperArmAdjust.abductedUpperArm;
  rulaQuestions[RulaItems.UPPER_ARM_ADJUST].items[2].selected =
    rulaResult.upperArmAdjust.supportedArm;
  setIndexById(
    rulaQuestions[RulaItems.LOWER_ARM_ANGLE],
    rulaResult.lowerArmAngle,
  );
  rulaQuestions[RulaItems.LOWER_ARM_ADJUST].items[0].selected =
    rulaResult.lowerArmAdjust;

  setIndexById(rulaQuestions[RulaItems.WRIST_ANGLE], rulaResult.wristAngle);
  rulaQuestions[RulaItems.BENT_WRIST].items[0].selected = rulaResult.bentWrist;
  setIndexById(rulaQuestions[RulaItems.WRIST_TWIST], rulaResult.wristTwist);
  rulaQuestions[RulaItems.MUSCLE_USE].items[0].selected = rulaResult.muscleUse;
  setIndexById(rulaQuestions[RulaItems.FORCE_MODE], rulaResult.forceMode);

  setIndexById(rulaQuestions[RulaItems.NECK_ANGLE], rulaResult.neckAngle);
  rulaQuestions[RulaItems.NECK_ADJUST].items[0].selected =
    rulaResult.neckAdjust.twisted;
  rulaQuestions[RulaItems.NECK_ADJUST].items[1].selected =
    rulaResult.neckAdjust.sideBending;
  setIndexById(rulaQuestions[RulaItems.TRUNK_ANGLE], rulaResult.trunkAngle);
  rulaQuestions[RulaItems.TRUNK_ADJUST].items[0].selected =
    rulaResult.trunkAdjust.twisted;
  rulaQuestions[RulaItems.TRUNK_ADJUST].items[1].selected =
    rulaResult.trunkAdjust.sideBending;

  setIndexById(rulaQuestions[RulaItems.LEG_SUPPORTED], rulaResult.legSupported);

  return rulaQuestions;
};

export const getRulaResult = (rulaQuestions: RulaQuestions): RulaDto => ({
  upperArmAngle: getIdOfSelectedItem(rulaQuestions[RulaItems.UPPER_ARM_ANGLE]),
  upperArmAdjust: {
    raisedShoulder: rulaQuestions[RulaItems.UPPER_ARM_ADJUST].items[0].selected,
    abductedUpperArm:
      rulaQuestions[RulaItems.UPPER_ARM_ADJUST].items[1].selected,
    supportedArm: rulaQuestions[RulaItems.UPPER_ARM_ADJUST].items[2].selected,
  },
  lowerArmAngle: getIdOfSelectedItem(rulaQuestions[RulaItems.LOWER_ARM_ANGLE]),
  lowerArmAdjust: rulaQuestions[RulaItems.LOWER_ARM_ADJUST].items[0].selected,
  wristAngle: getIdOfSelectedItem(rulaQuestions[RulaItems.WRIST_ANGLE]),
  bentWrist: rulaQuestions[RulaItems.BENT_WRIST].items[0].selected,
  wristTwist: getIdOfSelectedItem(rulaQuestions[RulaItems.WRIST_TWIST]),
  muscleUse: rulaQuestions[RulaItems.MUSCLE_USE].items[0].selected,
  forceMode: getIdOfSelectedItem(rulaQuestions[RulaItems.FORCE_MODE]),
  neckAngle: getIdOfSelectedItem(rulaQuestions[RulaItems.NECK_ANGLE]),
  neckAdjust: {
    twisted: rulaQuestions[RulaItems.NECK_ADJUST].items[0].selected,
    sideBending: rulaQuestions[RulaItems.NECK_ADJUST].items[1].selected,
  },
  trunkAngle: getIdOfSelectedItem(rulaQuestions[RulaItems.TRUNK_ANGLE]),
  trunkAdjust: {
    twisted: rulaQuestions[RulaItems.TRUNK_ADJUST].items[0].selected,
    sideBending: rulaQuestions[RulaItems.TRUNK_ADJUST].items[1].selected,
  },
  legSupported: getIdOfSelectedItem(rulaQuestions[RulaItems.LEG_SUPPORTED]),
});

const A = [
  [1, 2, 2, 2, 2, 3, 3, 3],
  [2, 2, 2, 2, 3, 3, 3, 3],
  [2, 3, 3, 3, 3, 3, 4, 4],
  [2, 3, 3, 3, 3, 4, 4, 4],
  [3, 3, 3, 3, 3, 4, 4, 4],
  [3, 4, 4, 4, 4, 4, 5, 5],
  [3, 3, 4, 4, 4, 4, 5, 5],
  [3, 4, 4, 4, 4, 4, 5, 5],
  [4, 4, 4, 4, 4, 5, 5, 5],
  [4, 4, 4, 4, 4, 5, 5, 5],
  [4, 4, 4, 4, 4, 5, 5, 5],
  [4, 4, 4, 5, 5, 5, 6, 6],
  [5, 5, 5, 5, 5, 6, 6, 7],
  [5, 6, 6, 6, 6, 6, 7, 7],
  [6, 6, 6, 7, 7, 7, 7, 8],
  [7, 7, 7, 7, 7, 8, 8, 9],
  [8, 8, 8, 8, 8, 9, 9, 9],
  [9, 9, 9, 9, 9, 9, 9, 9],
];

const B = [
  [1, 3, 2, 3, 3, 4, 5, 5, 6, 6, 7, 7],
  [2, 3, 2, 3, 4, 5, 5, 5, 6, 7, 7, 7],
  [3, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 7],
  [5, 5, 5, 6, 6, 7, 7, 7, 7, 7, 8, 8],
  [7, 7, 7, 7, 7, 8, 8, 8, 8, 8, 8, 8],
  [8, 8, 8, 8, 8, 8, 8, 9, 9, 9, 9, 9],
];

const C = [
  [1, 2, 3, 3, 4, 5, 5],
  [2, 2, 3, 4, 4, 5, 5],
  [3, 3, 3, 4, 4, 5, 6],
  [3, 3, 3, 4, 5, 6, 6],
  [4, 4, 4, 5, 6, 7, 7],
  [4, 4, 5, 6, 6, 7, 7],
  [5, 5, 6, 6, 7, 7, 7],
  [5, 5, 6, 7, 7, 7, 7],
];

export const getRulaScore = (rulaResult: RulaDto) => {
  const rulaQuestions = getRulaDataFromResult(rulaResult);

  const A_i = Math.max(
    0,
    getValueOfRulaQuestions(rulaQuestions, [
      RulaItems.UPPER_ARM_ANGLE,
      RulaItems.UPPER_ARM_ADJUST,
    ]) - 1,
  );
  const A_j =
    getValueOfRulaQuestions(rulaQuestions, [
      RulaItems.LOWER_ARM_ANGLE,
      RulaItems.LOWER_ARM_ADJUST,
    ]) - 1;

  const A_k =
    getValueOfRulaQuestions(rulaQuestions, [RulaItems.WRIST_ANGLE]) - 1;
  const A_l =
    getValueOfRulaQuestions(rulaQuestions, [RulaItems.WRIST_TWIST]) - 1;

  const muscleUseScore = getValueOfRulaQuestions(rulaQuestions, [
    RulaItems.MUSCLE_USE,
  ]);

  const forceScore = getValueOfRulaQuestions(rulaQuestions, [
    RulaItems.FORCE_MODE,
  ]);
  const scoreA = A[A_i * 3 + A_j][A_k * 2 + A_l] + muscleUseScore + forceScore;

  const B_i =
    getValueOfRulaQuestions(rulaQuestions, [
      RulaItems.NECK_ANGLE,
      RulaItems.NECK_ADJUST,
    ]) - 1;

  const B_j =
    getValueOfRulaQuestions(rulaQuestions, [
      RulaItems.TRUNK_ANGLE,
      RulaItems.TRUNK_ADJUST,
    ]) - 1;

  const B_k =
    getValueOfRulaQuestions(rulaQuestions, [RulaItems.LEG_SUPPORTED]) - 1;

  const scoreB = B[B_i][B_j * 2 + B_k] + muscleUseScore + forceScore;

  const scoreC = C[Math.min(scoreA - 1, 7)][Math.min(scoreB - 1, 6)];

  return scoreC;
};
