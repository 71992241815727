export type AssessmentMethodsDetailType = {
  method: "REBA" | "RULA" | "MMH" | "ERA";
  title: string;
  subtitle: string;
  detail: string;
};

export const assessmentMethodsDetail: AssessmentMethodsDetailType[] = [
  {
    method: "REBA",
    title: "REBA",
    subtitle: "Rapid Entire Body Assessment",
    detail:
      "The Rapid Entire Body Assessment (REBA) is a simple observational assessment tool used by ergonomists and other safety professionals to evaluate the physical stress and discomfort that workers may experience during their job tasks. It helps to identify potential ergonomic issues in the workplace early on before they can cause costly and painful musculoskeletal disorders by scoring the postures observed by the worker during the task. REBA consists of a series of steps that involve observing the worker's posture, then determining and assigning scores to different body parts based on their positioning and degree of stress. The end-result provides an indication of the overall level of ergonomic risk associated with the task as calculated by combining the individual segment scores.",
  },
  {
    method: "RULA",
    title: "RULA",
    subtitle: "Rapid Upper Limb Assessment",
    detail:
      "The Rapid Upper Limb Assessment (RULA) is a diagnostic tool used to assess the ergonomic risk levels associated with upper limb disorders resulting from repetitive strain and muscular discomfort in the workplace. Developed to evaluate the posture of the neck, trunk, and upper limbs along with muscle function and external loads, RULA uses a systematic process to grade the risk of injury based on the observation of static and dynamic positions of the body during work tasks. The assessment involves scoring various body regions including the arms, wrists, neck, and trunk, with higher scores indicating greater risk of musculoskeletal injury. The final score is used to determine the level of action required to reduce risk, ranging from no action to immediate investigation and changes in the workplace. RULA is widely utilized in occupational health and ergonomics to help identify work activities that pose increased risk for upper limb disorders, promoting early intervention and the redesign of work tasks to improve posture and reduce muscular strain.",
  },
  {
    method: "MMH",
    title: "MMH",
    subtitle: "Liberty Mutual Manual Materials Handling",
    detail:
      "The Liberty Mutual manual materials handling equations are a set of guidelines that help to evaluate the risk of injury associated with manually lifting, lowering, carrying, pushing, or pulling materials in the workplace. These guidelines are based on biomechanical models that consider various factors, such as frequency the weight of the object, the horizontal distance between the worker and the object, the vertical distance of the lift, and the posture of the worker. Based on these factors, the equations provide recommendations for safe manual material handling guidelines, including the maximum weight that should be lifted, lowered, carried, pushed, or pulled, the percent of population capable and the appropriate grip and posture. The purpose of these equations is to help prevent musculoskeletal injuries, such as strains and sprains, that can occur from repetitive manual lifting tasks.",
  },
  {
    method: "ERA",
    title: "ERA",
    subtitle: "Ergonomics Risk Assessment",
    detail:
      "The Ergonomics Risk Assessment (ERA) is a cyclic job analysis tool focusing on the upper body. It is best suited for general screening and assessing existing production operations. The tool captures job elements based on representative video samples, allowing for detailed observation and scoring of posture, force, repetition, and static exertion. ERA emphasizes identifying thresholds for each risk factor, categorizing overall risk into low, moderate, high, or very high levels. The process includes evaluating video footage, engaging operators for feedback, and ensuring consistent assessment practices. The results guide recommendations for monitoring, further investigation, or immediate corrective actions to mitigate ergonomic risks in the workplace.",
  },
];
