export function deepCloneQuestions(obj: any): any {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => deepCloneQuestions(item));
  }

  const clonedObj: any = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      clonedObj[key] = deepCloneQuestions(obj[key]);
    }
  }

  return clonedObj;
}

export const calculateOrganAngle = (leftAngle?: number, rightAngle?: number) => {
  let angle = 0;
  if (leftAngle) angle += leftAngle;
  if (rightAngle) angle += rightAngle;
  if (leftAngle && rightAngle) angle /= 2;
  return angle;
};
