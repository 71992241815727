import { api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    authControllerLogin: build.mutation<
      AuthControllerLoginApiResponse,
      AuthControllerLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth`,
        method: "POST",
        body: queryArg.loginDto,
      }),
    }),
    authControllerSignup: build.mutation<
      AuthControllerSignupApiResponse,
      AuthControllerSignupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth/signup`,
        method: "POST",
        body: queryArg.userCreateDto,
      }),
    }),
    usersControllerCreateNewUser: build.mutation<
      UsersControllerCreateNewUserApiResponse,
      UsersControllerCreateNewUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/create`,
        method: "POST",
        body: queryArg.userCreateDto,
      }),
    }),
    usersControllerUpdateExistingUser: build.mutation<
      UsersControllerUpdateExistingUserApiResponse,
      UsersControllerUpdateExistingUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/update`,
        method: "PUT",
        body: queryArg.userUpdateDto,
      }),
    }),
    usersControllerSearchUsers: build.query<
      UsersControllerSearchUsersApiResponse,
      UsersControllerSearchUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/search`,
        params: {
          username: queryArg.username,
          firstName: queryArg.firstName,
          lastName: queryArg.lastName,
          email: queryArg.email,
          isActive: queryArg.isActive,
          limitUsageMailSent: queryArg.limitUsageMailSent,
        },
      }),
    }),
    usersControllerUpdateUserProfile: build.mutation<
      UsersControllerUpdateUserProfileApiResponse,
      UsersControllerUpdateUserProfileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/profile`,
        method: "PUT",
        body: queryArg.userUpdateDto,
      }),
    }),
    usersControllerSuspendUserByUsername: build.mutation<
      UsersControllerSuspendUserByUsernameApiResponse,
      UsersControllerSuspendUserByUsernameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/suspend/${queryArg.username}`,
        method: "PUT",
      }),
    }),
    usersControllerActivateUserByUsername: build.mutation<
      UsersControllerActivateUserByUsernameApiResponse,
      UsersControllerActivateUserByUsernameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/activate/${queryArg.username}`,
        method: "PUT",
      }),
    }),
    usersControllerAssignUserRole: build.mutation<
      UsersControllerAssignUserRoleApiResponse,
      UsersControllerAssignUserRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/role`,
        method: "POST",
        body: queryArg.userRoleDto,
      }),
    }),
    usersControllerSendResetPasswordEmail: build.mutation<
      UsersControllerSendResetPasswordEmailApiResponse,
      UsersControllerSendResetPasswordEmailApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/password/reset/email`,
        method: "POST",
        body: queryArg.mailResetPasswordDto,
      }),
    }),
    usersControllerVerifyResetPasswordCredentials: build.query<
      UsersControllerVerifyResetPasswordCredentialsApiResponse,
      UsersControllerVerifyResetPasswordCredentialsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/password/reset/verify`,
        params: { resetCode: queryArg.resetCode, email: queryArg.email },
      }),
    }),
    usersControllerResetPassword: build.mutation<
      UsersControllerResetPasswordApiResponse,
      UsersControllerResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/password/reset`,
        method: "POST",
        body: queryArg.changePasswordDto,
        params: { resetCode: queryArg.resetCode, email: queryArg.email },
      }),
    }),
    usersControllerChangeUserPassword: build.mutation<
      UsersControllerChangeUserPasswordApiResponse,
      UsersControllerChangeUserPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/password/change`,
        method: "POST",
        body: queryArg.changePasswordDto,
      }),
    }),
    usersControllerGetUserUsage: build.query<
      UsersControllerGetUserUsageApiResponse,
      UsersControllerGetUserUsageApiArg
    >({
      query: () => ({ url: `/api/v1/users/usage/me` }),
    }),
    usersControllerGetAllUsersUsage: build.query<
      UsersControllerGetAllUsersUsageApiResponse,
      UsersControllerGetAllUsersUsageApiArg
    >({
      query: () => ({ url: `/api/v1/users/usage/all` }),
    }),
    usersControllerSetUserUsageLimit: build.mutation<
      UsersControllerSetUserUsageLimitApiResponse,
      UsersControllerSetUserUsageLimitApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/usage/limit`,
        method: "POST",
        body: queryArg.userLimitDto,
      }),
    }),
    usersControllerCheckUserLimitReached: build.query<
      UsersControllerCheckUserLimitReachedApiResponse,
      UsersControllerCheckUserLimitReachedApiArg
    >({
      query: () => ({ url: `/api/v1/users/usage/limit/me` }),
    }),
    tasksControllerGetUserTasksWithPagination: build.query<
      TasksControllerGetUserTasksWithPaginationApiResponse,
      TasksControllerGetUserTasksWithPaginationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/with-pagination`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          search: queryArg.search,
          category: queryArg.category,
          tag: queryArg.tag,
          orderBy: queryArg.orderBy,
          order: queryArg.order,
          creator: queryArg.creator,
        },
      }),
    }),
    tasksControllerGetAdminTasksWithPagination: build.query<
      TasksControllerGetAdminTasksWithPaginationApiResponse,
      TasksControllerGetAdminTasksWithPaginationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/admin-with-pagination`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          search: queryArg.search,
          category: queryArg.category,
          tag: queryArg.tag,
          orderBy: queryArg.orderBy,
          order: queryArg.order,
          creator: queryArg.creator,
        },
      }),
    }),
    tasksControllerUpdateUserTask: build.mutation<
      TasksControllerUpdateUserTaskApiResponse,
      TasksControllerUpdateUserTaskApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks`,
        method: "PUT",
        body: queryArg.taskUpdateDto,
      }),
    }),
    tasksControllerDeleteTasks: build.mutation<
      TasksControllerDeleteTasksApiResponse,
      TasksControllerDeleteTasksApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks`,
        method: "DELETE",
        body: queryArg.deleteTaskDto,
      }),
    }),
    tasksControllerGetTaskByIdForUser: build.query<
      TasksControllerGetTaskByIdForUserApiResponse,
      TasksControllerGetTaskByIdForUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/task`,
        params: { id: queryArg.id },
      }),
    }),
    tasksControllerDownloadTaskResultAsCsvWithApiBearer: build.query<
      TasksControllerDownloadTaskResultAsCsvWithApiBearerApiResponse,
      TasksControllerDownloadTaskResultAsCsvWithApiBearerApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/task/csv`,
        params: { id: queryArg.id },
      }),
    }),
    tasksControllerGetUserTagsForCurrentUser: build.query<
      TasksControllerGetUserTagsForCurrentUserApiResponse,
      TasksControllerGetUserTagsForCurrentUserApiArg
    >({
      query: () => ({ url: `/api/v1/tasks/get/tag` }),
    }),
    tasksControllerChangeTaskStateAsAdmin: build.mutation<
      TasksControllerChangeTaskStateAsAdminApiResponse,
      TasksControllerChangeTaskStateAsAdminApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/change-task-state`,
        method: "PATCH",
        body: queryArg.changeTaskStateDto,
      }),
    }),
    tasksControllerSaveTaskResultsInNewSchema: build.mutation<
      TasksControllerSaveTaskResultsInNewSchemaApiResponse,
      TasksControllerSaveTaskResultsInNewSchemaApiArg
    >({
      query: () => ({
        url: `/api/v1/tasks/save-task-results-in-new-schema`,
        method: "POST",
      }),
    }),
    tasksControllerUpdateObservationAndRecommendation: build.mutation<
      TasksControllerUpdateObservationAndRecommendationApiResponse,
      TasksControllerUpdateObservationAndRecommendationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/update-observation-recommendation`,
        method: "PATCH",
        body: queryArg.updateObservationRecommendationDto,
      }),
    }),
    tasksControllerGetPersonsOfTask: build.query<
      TasksControllerGetPersonsOfTaskApiResponse,
      TasksControllerGetPersonsOfTaskApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/task/${queryArg.taskId}/persons`,
      }),
    }),
    tasksControllerGetPersonFrames: build.query<
      TasksControllerGetPersonFramesApiResponse,
      TasksControllerGetPersonFramesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/person/${queryArg.personId}/frames`,
      }),
    }),
    categoryControllerCreateCategory: build.mutation<
      CategoryControllerCreateCategoryApiResponse,
      CategoryControllerCreateCategoryApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/category`,
        method: "POST",
        body: queryArg.createCategoryDto,
      }),
    }),
    categoryControllerDeleteCategory: build.mutation<
      CategoryControllerDeleteCategoryApiResponse,
      CategoryControllerDeleteCategoryApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/category`,
        method: "DELETE",
        body: queryArg.idDto,
      }),
    }),
    categoryControllerUpdateCategory: build.mutation<
      CategoryControllerUpdateCategoryApiResponse,
      CategoryControllerUpdateCategoryApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/category`,
        method: "PUT",
        body: queryArg.updateCategoryDto,
        params: { id: queryArg.id },
      }),
    }),
    categoryControllerGetAllCategories: build.query<
      CategoryControllerGetAllCategoriesApiResponse,
      CategoryControllerGetAllCategoriesApiArg
    >({
      query: () => ({ url: `/api/v1/category` }),
    }),
    categoryControllerCreateSystemCategory: build.mutation<
      CategoryControllerCreateSystemCategoryApiResponse,
      CategoryControllerCreateSystemCategoryApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/category/admin-system-category`,
        method: "POST",
        body: queryArg.createSystemCategoryDto,
      }),
    }),
    assessmentsControllerGetAssessments: build.query<
      AssessmentsControllerGetAssessmentsApiResponse,
      AssessmentsControllerGetAssessmentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/assessments`,
        params: { id: queryArg.id },
      }),
    }),
    assessmentsControllerCreateAssessment: build.mutation<
      AssessmentsControllerCreateAssessmentApiResponse,
      AssessmentsControllerCreateAssessmentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/assessments/assessments`,
        method: "POST",
        body: queryArg.assessmentDto,
      }),
    }),
    assessmentsControllerUpdateAssessment: build.mutation<
      AssessmentsControllerUpdateAssessmentApiResponse,
      AssessmentsControllerUpdateAssessmentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/assessments/assessments`,
        method: "PUT",
        body: queryArg.updateAssessmentDto,
      }),
    }),
    assessmentsControllerDeleteAssessment: build.mutation<
      AssessmentsControllerDeleteAssessmentApiResponse,
      AssessmentsControllerDeleteAssessmentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/assessments/assessments`,
        method: "DELETE",
        body: queryArg.idDto,
      }),
    }),
    rolesControllerReadPermissions: build.query<
      RolesControllerReadPermissionsApiResponse,
      RolesControllerReadPermissionsApiArg
    >({
      query: () => ({ url: `/api/v1/roles/permissions` }),
    }),
    rolesControllerCreateRole: build.mutation<
      RolesControllerCreateRoleApiResponse,
      RolesControllerCreateRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/roles`,
        method: "POST",
        body: queryArg.roleDto,
      }),
    }),
    rolesControllerUpdateRole: build.mutation<
      RolesControllerUpdateRoleApiResponse,
      RolesControllerUpdateRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/roles`,
        method: "PUT",
        body: queryArg.updateRoleDto,
      }),
    }),
    rolesControllerFindRole: build.query<
      RolesControllerFindRoleApiResponse,
      RolesControllerFindRoleApiArg
    >({
      query: () => ({ url: `/api/v1/roles` }),
    }),
    rolesControllerDeleteRole: build.mutation<
      RolesControllerDeleteRoleApiResponse,
      RolesControllerDeleteRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/roles`,
        method: "DELETE",
        body: queryArg.idDto,
      }),
    }),
    rolesControllerReadRole: build.query<
      RolesControllerReadRoleApiResponse,
      RolesControllerReadRoleApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/roles/${queryArg.roleId}` }),
    }),
    mediaControllerGetMediaUrl: build.query<
      MediaControllerGetMediaUrlApiResponse,
      MediaControllerGetMediaUrlApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/media/url/${queryArg.mediaName}` }),
    }),
    mediaControllerGetVideoStream: build.query<
      MediaControllerGetVideoStreamApiResponse,
      MediaControllerGetVideoStreamApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/media/video/${queryArg.mediaName}`,
      }),
    }),
    mediaControllerGetPoseUrl: build.query<
      MediaControllerGetPoseUrlApiResponse,
      MediaControllerGetPoseUrlApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/media/pose/${queryArg.taskId}` }),
    }),
    mediaControllerUploadVideo: build.mutation<
      MediaControllerUploadVideoApiResponse,
      MediaControllerUploadVideoApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/media`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type AuthControllerLoginApiResponse = /** status 200  */ LoginRo;
export type AuthControllerLoginApiArg = {
  loginDto: LoginDto;
};
export type AuthControllerSignupApiResponse = /** status 201  */ LoginRo;
export type AuthControllerSignupApiArg = {
  userCreateDto: UserCreateDto;
};
export type UsersControllerCreateNewUserApiResponse = /** status 201  */ UserRo;
export type UsersControllerCreateNewUserApiArg = {
  userCreateDto: UserCreateDto;
};
export type UsersControllerUpdateExistingUserApiResponse = unknown;
export type UsersControllerUpdateExistingUserApiArg = {
  userUpdateDto: UserUpdateDto;
};
export type UsersControllerSearchUsersApiResponse = /** status 200  */ UsersRo;
export type UsersControllerSearchUsersApiArg = {
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  isActive?: boolean;
  limitUsageMailSent?: boolean;
};
export type UsersControllerUpdateUserProfileApiResponse =
  /** status 200  */ UserRo;
export type UsersControllerUpdateUserProfileApiArg = {
  userUpdateDto: UserUpdateDto;
};
export type UsersControllerSuspendUserByUsernameApiResponse = unknown;
export type UsersControllerSuspendUserByUsernameApiArg = {
  username: string;
};
export type UsersControllerActivateUserByUsernameApiResponse = unknown;
export type UsersControllerActivateUserByUsernameApiArg = {
  username: string;
};
export type UsersControllerAssignUserRoleApiResponse = unknown;
export type UsersControllerAssignUserRoleApiArg = {
  userRoleDto: UserRoleDto;
};
export type UsersControllerSendResetPasswordEmailApiResponse = unknown;
export type UsersControllerSendResetPasswordEmailApiArg = {
  mailResetPasswordDto: MailResetPasswordDto;
};
export type UsersControllerVerifyResetPasswordCredentialsApiResponse = unknown;
export type UsersControllerVerifyResetPasswordCredentialsApiArg = {
  resetCode: number;
  email: string;
};
export type UsersControllerResetPasswordApiResponse = unknown;
export type UsersControllerResetPasswordApiArg = {
  resetCode: number;
  email: string;
  changePasswordDto: ChangePasswordDto;
};
export type UsersControllerChangeUserPasswordApiResponse = unknown;
export type UsersControllerChangeUserPasswordApiArg = {
  changePasswordDto: ChangePasswordDto;
};
export type UsersControllerGetUserUsageApiResponse =
  /** status 200  */ UserUsageAndLimitRo;
export type UsersControllerGetUserUsageApiArg = void;
export type UsersControllerGetAllUsersUsageApiResponse =
  /** status 200  */ UsersUsageAndLimitRo;
export type UsersControllerGetAllUsersUsageApiArg = void;
export type UsersControllerSetUserUsageLimitApiResponse =
  /** status 200  */ UsersUsageAndLimitRo;
export type UsersControllerSetUserUsageLimitApiArg = {
  userLimitDto: UserLimitDto;
};
export type UsersControllerCheckUserLimitReachedApiResponse =
  /** status 200  */ LimitReachedRo;
export type UsersControllerCheckUserLimitReachedApiArg = void;
export type TasksControllerGetUserTasksWithPaginationApiResponse =
  /** status 200  */ TasksRo;
export type TasksControllerGetUserTasksWithPaginationApiArg = {
  page?: number;
  limit?: number;
  search?: string;
  category?: string;
  tag?: string;
  orderBy?: string;
  order?: "asc" | "desc";
  creator?: string;
};
export type TasksControllerGetAdminTasksWithPaginationApiResponse =
  /** status 200  */ TasksRo;
export type TasksControllerGetAdminTasksWithPaginationApiArg = {
  page?: number;
  limit?: number;
  search?: string;
  category?: string;
  tag?: string;
  orderBy?: string;
  order?: "asc" | "desc";
  creator?: string;
};
export type TasksControllerUpdateUserTaskApiResponse = unknown;
export type TasksControllerUpdateUserTaskApiArg = {
  taskUpdateDto: TaskUpdateDto;
};
export type TasksControllerDeleteTasksApiResponse = unknown;
export type TasksControllerDeleteTasksApiArg = {
  deleteTaskDto: DeleteTaskDto;
};
export type TasksControllerGetTaskByIdForUserApiResponse =
  /** status 200  */ TaskRo;
export type TasksControllerGetTaskByIdForUserApiArg = {
  id: string;
};
export type TasksControllerDownloadTaskResultAsCsvWithApiBearerApiResponse =
  unknown;
export type TasksControllerDownloadTaskResultAsCsvWithApiBearerApiArg = {
  id: string;
};
export type TasksControllerGetUserTagsForCurrentUserApiResponse =
  /** status 200  */ TagsRo;
export type TasksControllerGetUserTagsForCurrentUserApiArg = void;
export type TasksControllerChangeTaskStateAsAdminApiResponse = unknown;
export type TasksControllerChangeTaskStateAsAdminApiArg = {
  changeTaskStateDto: ChangeTaskStateDto;
};
export type TasksControllerSaveTaskResultsInNewSchemaApiResponse = unknown;
export type TasksControllerSaveTaskResultsInNewSchemaApiArg = void;
export type TasksControllerUpdateObservationAndRecommendationApiResponse =
  unknown;
export type TasksControllerUpdateObservationAndRecommendationApiArg = {
  updateObservationRecommendationDto: UpdateObservationRecommendationDto;
};
export type TasksControllerGetPersonsOfTaskApiResponse =
  /** status 200  */ Person[];
export type TasksControllerGetPersonsOfTaskApiArg = {
  taskId: string;
};
export type TasksControllerGetPersonFramesApiResponse = unknown;
export type TasksControllerGetPersonFramesApiArg = {
  personId: string;
};
export type CategoryControllerCreateCategoryApiResponse =
  /** status 200  */ CreateCategoryRo;
export type CategoryControllerCreateCategoryApiArg = {
  createCategoryDto: CreateCategoryDto;
};
export type CategoryControllerDeleteCategoryApiResponse = unknown;
export type CategoryControllerDeleteCategoryApiArg = {
  idDto: IdDto;
};
export type CategoryControllerUpdateCategoryApiResponse = unknown;
export type CategoryControllerUpdateCategoryApiArg = {
  id: string;
  updateCategoryDto: UpdateCategoryDto;
};
export type CategoryControllerGetAllCategoriesApiResponse =
  /** status 200  */ CategoriesRo;
export type CategoryControllerGetAllCategoriesApiArg = void;
export type CategoryControllerCreateSystemCategoryApiResponse =
  /** status 200  */ CreateCategoryRo;
export type CategoryControllerCreateSystemCategoryApiArg = {
  createSystemCategoryDto: CreateSystemCategoryDto;
};
export type AssessmentsControllerGetAssessmentsApiResponse =
  /** status 200  */ AssessmentRo[];
export type AssessmentsControllerGetAssessmentsApiArg = {
  id: string;
};
export type AssessmentsControllerCreateAssessmentApiResponse = unknown;
export type AssessmentsControllerCreateAssessmentApiArg = {
  assessmentDto: AssessmentDto;
};
export type AssessmentsControllerUpdateAssessmentApiResponse = unknown;
export type AssessmentsControllerUpdateAssessmentApiArg = {
  updateAssessmentDto: UpdateAssessmentDto;
};
export type AssessmentsControllerDeleteAssessmentApiResponse = unknown;
export type AssessmentsControllerDeleteAssessmentApiArg = {
  idDto: IdDto;
};
export type RolesControllerReadPermissionsApiResponse =
  /** status 200  */ PermissionViewRo[];
export type RolesControllerReadPermissionsApiArg = void;
export type RolesControllerCreateRoleApiResponse = /** status 201  */ RoleRo;
export type RolesControllerCreateRoleApiArg = {
  roleDto: RoleDto;
};
export type RolesControllerUpdateRoleApiResponse = unknown;
export type RolesControllerUpdateRoleApiArg = {
  updateRoleDto: UpdateRoleDto;
};
export type RolesControllerFindRoleApiResponse = /** status 200  */ RolesRo;
export type RolesControllerFindRoleApiArg = void;
export type RolesControllerDeleteRoleApiResponse = unknown;
export type RolesControllerDeleteRoleApiArg = {
  idDto: IdDto;
};
export type RolesControllerReadRoleApiResponse = /** status 200  */ RoleRo;
export type RolesControllerReadRoleApiArg = {
  roleId: string;
};
export type MediaControllerGetMediaUrlApiResponse = /** status 200  */ UrlRo;
export type MediaControllerGetMediaUrlApiArg = {
  mediaName: string;
};
export type MediaControllerGetVideoStreamApiResponse = unknown;
export type MediaControllerGetVideoStreamApiArg = {
  mediaName: string;
};
export type MediaControllerGetPoseUrlApiResponse = /** status 200  */ string;
export type MediaControllerGetPoseUrlApiArg = {
  taskId: string;
};
export type MediaControllerUploadVideoApiResponse =
  /** status 201  */ MinimalTaskRo;
export type MediaControllerUploadVideoApiArg = {
  body: {
    file: Blob;
    categoryId: string;
    name: string;
    description?: string;
    tagName?: string;
    blurFace: boolean;
    blurBack: boolean;
    multiTarget: boolean;
    useCameraZ: boolean;
  };
};
export type UserLimitRo = {
  count?: number;
  duration?: number;
  date?: string;
  lastUsageCount: number;
  lastUsageDuration: number;
  lastUsageDate?: string;
};
export type UserRo = {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  roleNames: string[];
  limit: UserLimitRo;
  isActive: boolean;
};
export type LoginRo = {
  token: string;
  user: UserRo;
};
export type LoginDto = {
  username: string;
  password: string;
};
export type UserCreateDto = {
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  email: string;
};
export type UserUpdateDto = {
  username: string;
  firstName?: string;
  lastName?: string;
  email?: string;
};
export type UsersRo = {
  users: UserRo[];
};
export type UserRoleDto = {
  username: string;
  roleIds: string[];
};
export type MailResetPasswordDto = {
  email: string;
  callbackUrl: string;
};
export type ChangePasswordDto = {
  password: string;
};
export type UserUsageRo = {
  duration?: number;
  count?: number;
};
export type UserUsageAndLimitRo = {
  creator: string;
  usage: UserUsageRo;
  limit: UserLimitRo;
  userCreateDate: string;
};
export type UsersUsageAndLimitRo = {
  usersUsageAndLimit: UserUsageAndLimitRo[];
};
export type UserLimitDto = {
  username: string;
  count?: number;
  duration?: number;
  date?: string;
};
export type LimitReachedRo = {
  reached: boolean;
};
export type MinimalCategoryRo = {
  id: string;
  name: string;
};
export type TagRo = {
  id: string;
  name: string;
};
export type MinimalTaskRo = {
  /** Unique identifier for the task */
  id: string;
  /** Username of the task creator */
  creator: string;
  /** Original filename of the uploaded video */
  fileName: string;
  /** Current state of the task processing */
  state: "PROCESSING" | "PENDING" | "READY" | "ERROR";
  /** Creation timestamp of the task */
  createdAt: string;
  /** Category information in minimal form */
  category?: MinimalCategoryRo;
  /** Whether the task has been marked as deleted */
  isDeleted: boolean;
  /** Name of the task */
  name: string;
  /** Detailed description of the task */
  description: string;
  /** Associated tag information */
  tag?: TagRo;
  /** Start time of task processing */
  taskStart: string;
  /** End time of task processing */
  taskEnd: string;
  /** Duration of the video in seconds */
  duration: number;
  /** Output filename after processing */
  outFileName: string;
  useCameraZ: boolean;
  /** Overall risk score calculated from ergonomic assessment */
  riskScore?: number;
  /** Observations about ergonomic risks */
  observation: string;
  /** Recommendations for improving ergonomics */
  recommendation: string;
};
export type PaginationMeta = {
  total: number;
  page: number;
  limit: number;
};
export type TasksRo = {
  tasks: MinimalTaskRo[];
  meta?: PaginationMeta;
};
export type TaskUpdateDto = {
  id: string;
  tag?: string;
  description?: string;
  name: string;
};
export type DeleteTaskDto = {
  /** Array of task IDs to delete */
  ids: string[];
  /** Whether to permanently delete the tasks (admin only) */
  permanent: boolean;
};
export type ThresholdDto = {
  /** Marks the beginning of the first caution zone */
  threshold1: number;
  /** Marks the beginning of the safe zone */
  threshold2: number;
  /** Marks the end of the safe zone */
  threshold3: number;
  /** Marks the end of the second caution zone */
  threshold4: number;
};
export type SettingDto = {
  neck: ThresholdDto;
  back: ThresholdDto;
  rightKnee: ThresholdDto;
  leftKnee: ThresholdDto;
  rightUpperArm: ThresholdDto;
  leftUpperArm: ThresholdDto;
  rightLowerArm: ThresholdDto;
  leftLowerArm: ThresholdDto;
  rightWrist: ThresholdDto;
  leftWrist: ThresholdDto;
};
export type ResultRo = {
  neck?: number;
  back?: number;
  rightKnee?: number;
  leftKnee?: number;
  rightUpperArm?: number;
  leftUpperArm?: number;
  rightLowerArm?: number;
  leftLowerArm?: number;
  rightWrist?: number;
  leftWrist?: number;
};
export type TaskRo = {
  id: string;
  creator: string;
  fileName: string;
  state: "PROCESSING" | "PENDING" | "READY" | "ERROR";
  createdAt: string;
  category?: MinimalCategoryRo;
  isDeleted: boolean;
  name: string;
  description: string;
  tag?: TagRo;
  setting: SettingDto;
  inFileName: string;
  outFileName: string;
  useCameraZ: boolean;
  havePose: boolean;
  taskStart: string;
  taskEnd: string;
  duration: number;
  result: ResultRo[];
  riskScore?: number;
  observation: string;
  recommendation: string;
};
export type TagsRo = {
  tags: TagRo[];
};
export type ChangeTaskStateDto = {
  /** Unique identifier of the task to update */
  id: string;
  /** New state to set for the task (e.g., READY, ERROR, ...) */
  state: "PROCESSING" | "PENDING" | "READY" | "ERROR";
};
export type UpdateObservationRecommendationDto = {
  id: string;
  observation: string;
  recommendation: string;
};
export type Person = {};
export type CreateCategoryRo = {
  id: string;
};
export type CreateCategoryDto = {
  name: string;
  setting: SettingDto;
};
export type IdDto = {
  id: string;
};
export type UpdateCategoryDto = {
  name: string;
  setting: SettingDto;
};
export type CategoryRo = {
  id: string;
  creator: string;
  name: string;
  setting: SettingDto;
  isSystemCategory: boolean;
};
export type CategoriesRo = {
  categories: CategoryRo[];
};
export type CreateSystemCategoryDto = {
  name: string;
  setting: SettingDto;
  isSystemCategory?: boolean;
};
export type NeckAdjustDto = {
  twisted: boolean;
  sideBending: boolean;
};
export type TrunkAdjustDto = {
  twisted: boolean;
  sideBending: boolean;
};
export type UpperArmAdjustDto = {
  raisedShoulder: boolean;
  abductedUpperArm: boolean;
  supportedArm: boolean;
};
export type ActivityDto = {
  bodyPartHeldLong: boolean;
  repeatedSmallRangeAction: boolean;
  rapidLargeRangeChange: boolean;
};
export type RebaDto = {
  neckAngle: "ANGLE_0_MINUS" | "ANGLE_0_20" | "ANGLE_20_PLUS";
  neckAdjust: NeckAdjustDto;
  trunkAngle:
    | "ANGLE_0_MINUS"
    | "ANGLE_0_10"
    | "ANGLE_10_20"
    | "ANGLE_20_60"
    | "ANGLE_60_PLUS";
  trunkAdjust: TrunkAdjustDto;
  legDown: boolean;
  legAngle?: "ANGLE_0_30" | "ANGLE_30_60" | "ANGLE_60_PLUS";
  forceMode: "LOAD_0_11_LBS" | "LOAD_11_22_LBS" | "LOAD_22_PLUS_LBS";
  shock: boolean;
  upperArmAngle:
    | "ANGLE__20_MINUS"
    | "ANGLE__20_20"
    | "ANGLE_20_45"
    | "ANGLE_45_90"
    | "ANGLE_90_PLUS";
  upperArmAdjust: UpperArmAdjustDto;
  lowerArmAngle: "ANGLE_0_60_OR_100_PLUS" | "ANGLE_60_100";
  wristAngle: "ANGLE__15_15" | "ANGLE_GT_15";
  bentWrist: boolean;
  couplingMode: "GOOD" | "FAIR" | "POOR" | "UNACCEPTABLE";
  activity: ActivityDto;
};
export type RulaDto = {
  neckAngle: "ANGLE_0_MINUS" | "ANGLE_0_10" | "ANGLE_10_20" | "ANGLE_20_PLUS";
  neckAdjust: NeckAdjustDto;
  trunkAngle: "ANGLE_0_10" | "ANGLE_10_20" | "ANGLE_20_60" | "ANGLE_60_PLUS";
  trunkAdjust: TrunkAdjustDto;
  legSupported: boolean;
  muscleUse: boolean;
  forceMode:
    | "LOAD_0_4_LBS"
    | "LOAD_4_22_LBS"
    | "LOAD_4_22_LBS_REPEATED"
    | "LOAD_22_PLUS_LBS";
  upperArmAngle:
    | "ANGLE__20_MINUS"
    | "ANGLE__20_20"
    | "ANGLE_20_45"
    | "ANGLE_45_90"
    | "ANGLE_90_PLUS";
  upperArmAdjust: UpperArmAdjustDto;
  lowerArmAngle: "ANGLE_0_60_OR_100_PLUS" | "ANGLE_60_100";
  lowerArmAdjust: boolean;
  wristAngle: "ANGLE_0" | "ANGLE__15_15" | "ANGLE_GT_15";
  bentWrist: boolean;
  wristTwist: boolean;
};
export type LiftLowerTaskDataDto = {
  frequency: number;
  handCoupling: "GOOD" | "FAIR" | "POOR";
  startHandHeight: number;
  endHandHeight: number;
  startHandDistance: number;
  endHandDistance: number;
};
export type PushPullCarryTaskDataDto = {
  frequency: number;
  horizontalDistance: number;
  verticalHandHeight: number;
};
export type LiftLowerCarryLoadDto = {
  objectWeight: number;
};
export type PushPullLoadDto = {
  initialForce: number;
  sustainedForce: number;
};
export type MmhDto = {
  /** Type of manual material handling task being assessed */
  mode: "LIFT" | "LOWER" | "PUSH" | "PULL" | "CARRY";
  /** Task-specific data including measurements and frequencies */
  data: LiftLowerTaskDataDto | PushPullCarryTaskDataDto;
  /** Load-related measurements including weight or force requirements */
  load: LiftLowerCarryLoadDto | PushPullLoadDto;
};
export type EraJobInfoDto = {
  /** Name of the job being assessed */
  jobName?: string;
  /** Department where the job is performed */
  department?: string;
  /** Name of the assessor */
  assessor?: string;
  /** Work shift being assessed */
  shift?: string;
  /** Date of assessment */
  date?: Date;
  /** Time to complete one cycle of the task */
  cycleTime?: number;
};
export type BackPostureDto = {
  /** Back flexed > 20° from vertical */
  flexedOver20: boolean;
  /** Back extended from neutral position */
  extended: boolean;
  /** Back bent sideways > 20° from vertical */
  sidewaysOver20: boolean;
  /** Back twisted > 20° from forward position */
  twistedOver20: boolean;
};
export type BackSeverePostureDto = {
  /** Back flexed > 45° from vertical */
  flexedOver45: boolean;
  /** Back extended > 20° from neutral */
  extendedOver20: boolean;
};
export type BackForceDto = {
  /** Force applied > 10 lb */
  over10lb: boolean;
};
export type BackRepetitionDto = {
  /** Movement frequency > 2 per minute */
  over2PerMin: boolean;
};
export type StaticExertionDto = {
  /** Static posture held > 10 seconds */
  over10Sec: boolean;
};
export type BackAssessmentDto = {
  posture: BackPostureDto;
  severePosture: BackSeverePostureDto;
  force: BackForceDto;
  repetition: BackRepetitionDto;
  staticExertion: StaticExertionDto;
};
export type NeckPostureDto = {
  /** Neck flexed > 30° from vertical */
  flexedOver30: boolean;
  /** Neck extended from neutral position */
  extended: boolean;
  /** Neck bent sideways */
  sideways: boolean;
  /** Neck twisted from forward position */
  twisted: boolean;
};
export type NeckSeverePostureDto = {
  /** Neck flexed > 60° from vertical */
  flexedOver60: boolean;
  /** Neck extended > 30° from neutral */
  extendedOver30: boolean;
};
export type NeckRepetitionDto = {
  /** Movement frequency > 3 per minute */
  over3PerMin: boolean;
};
export type NeckAssessmentDto = {
  posture: NeckPostureDto;
  severePosture: NeckSeverePostureDto;
  repetition: NeckRepetitionDto;
  staticExertion: StaticExertionDto;
};
export type ShoulderPostureDto = {
  /** Shoulder flexed > 45° from vertical */
  flexedOver45: boolean;
  /** Shoulder extended from neutral position */
  extended: boolean;
  /** Shoulder abducted > 45° from vertical */
  abductedOver45: boolean;
};
export type ShoulderSeverePostureDto = {
  /** Shoulder flexed > 90° from vertical */
  flexedOver90: boolean;
  /** Shoulder abducted > 90° from vertical */
  abductedOver90: boolean;
};
export type ShoulderForceDto = {
  /** One-handed force > 10 lb */
  over10lb1Hand: boolean;
  /** Two-handed force > 15 lb */
  over15lb2Hand: boolean;
};
export type ShoulderRepetitionDto = {
  /** Movement frequency > 2.5 per minute */
  over2Point5PerMin: boolean;
};
export type ShoulderAssessmentDto = {
  posture: ShoulderPostureDto;
  severePosture: ShoulderSeverePostureDto;
  force: ShoulderForceDto;
  repetition: ShoulderRepetitionDto;
  staticExertion: StaticExertionDto;
};
export type ElbowPostureDto = {
  /** Elbow flexed < 60° */
  flexedUnder60: boolean;
  /** Elbow flexed > 100° */
  flexedOver100: boolean;
  /** Forearm rotated */
  forearmRotated: boolean;
};
export type ElbowSeverePostureDto = {
  /** Forearm rotated > 60° */
  forearmRotatedOver60: boolean;
};
export type ElbowForceDto = {
  /** One-handed force > 10 lb */
  over10lb1Hand: boolean;
  /** Two-handed force > 15 lb */
  over15lb2Hand: boolean;
};
export type ElbowRepetitionDto = {
  /** Movement frequency > 10 per minute */
  over10PerMin: boolean;
};
export type ElbowAssessmentDto = {
  posture: ElbowPostureDto;
  severePosture: ElbowSeverePostureDto;
  force: ElbowForceDto;
  repetition: ElbowRepetitionDto;
  staticExertion: StaticExertionDto;
};
export type WristPostureDto = {
  /** Wrist flexed > 45° */
  flexedOver45: boolean;
  /** Wrist extended > 45° */
  extendedOver45: boolean;
  /** Wrist radially deviated > 15° */
  radialDeviatedOver15: boolean;
  /** Wrist ulnarly deviated > 20° */
  ulnarDeviatedOver20: boolean;
};
export type WristSeverePostureDto = {
  /** Wrist flexed > 65° */
  flexedOver65: boolean;
  /** Wrist extended > 65° */
  extendedOver65: boolean;
};
export type WristForceDto = {
  /** Pinch grip force > 2 lb */
  pinchGripOver2lb: boolean;
  /** Power grip force > 10 lb */
  powerGripOver10lb: boolean;
};
export type WristRepetitionDto = {
  /** Movement frequency > 15 per minute */
  over15PerMin: boolean;
};
export type WristAssessmentDto = {
  posture: WristPostureDto;
  severePosture: WristSeverePostureDto;
  force: WristForceDto;
  repetition: WristRepetitionDto;
  staticExertion: StaticExertionDto;
};
export type AcceleratorDetailDto = {
  /** Body part affected by the stress factor */
  bodyPartAffected?: string;
  /** Task causing the stress */
  task?: string;
};
export type AcceleratorDto = {
  vibration?: AcceleratorDetailDto;
  contactStress?: AcceleratorDetailDto;
  impactStress?: AcceleratorDetailDto;
};
export type EraDto = {
  jobInfo: EraJobInfoDto;
  back: BackAssessmentDto;
  neck: NeckAssessmentDto;
  leftShoulder: ShoulderAssessmentDto;
  rightShoulder: ShoulderAssessmentDto;
  leftElbow: ElbowAssessmentDto;
  rightElbow: ElbowAssessmentDto;
  leftWrist: WristAssessmentDto;
  rightWrist: WristAssessmentDto;
  accelerators: AcceleratorDto;
};
export type AssessmentDataDto = {
  reba?: RebaDto;
  rula?: RulaDto;
  mmh?: MmhDto;
  era?: EraDto;
};
export type AssessmentRo = {
  /** Unique identifier of the assessment */
  id: string;
  /** Frame index in the video where assessment was made */
  frameIndex?: number;
  /** Assessment method used (REBA, RULA, MMH or ERA) */
  method: "REBA" | "RULA" | "MMH" | "ERA";
  /** Username of the assessment creator */
  creator: string;
  /** Optional descriptive name of the assessment */
  name?: string;
  /** Target person identifier in multi-person assessments */
  targetId?: number;
  /** Detailed assessment data containing measurements and calculations */
  data: AssessmentDataDto;
};
export type AssessmentDto = {
  /** The ID of the task this assessment belongs to */
  taskId: string;
  /** The assessment method to be used (REBA, RULA, MMH or ERA) */
  method: "REBA" | "RULA" | "MMH" | "ERA";
  /** Optional name for the assessment */
  name?: string;
  /** Frame index in the video where the assessment is made */
  frameIndex?: number;
  /** ID of the target person in the frame */
  targetId?: number;
  /** Assessment data containing REBA, RULA, or MMH specific measurements */
  data: AssessmentDataDto;
};
export type UpdateAssessmentDto = {
  /** ID of the assessment to update */
  id: string;
  /** Assessment method (REBA, RULA, MMH or ERA) */
  method: "REBA" | "RULA" | "MMH" | "ERA";
  /** New name for the assessment */
  name?: string;
  /** New frame index for the assessment */
  frameIndex?: number;
  /** New target person ID */
  targetId?: number;
  /** Updated assessment data */
  data: AssessmentDataDto;
};
export type PermissionViewRo = {
  id: string;
  category: string;
  group: string;
  name: string;
};
export type RoleRo = {
  id: string;
  name: string;
  permissions: "ADMIN"[];
};
export type RoleDto = {
  name: string;
  permissions: "ADMIN"[];
};
export type UpdateRoleDto = {
  id: string;
  name: string;
  permissions: "ADMIN"[];
};
export type RolesRo = {
  roles: RoleRo[];
};
export type UrlRo = {
  url: string;
};
export const {
  useAuthControllerLoginMutation,
  useAuthControllerSignupMutation,
  useUsersControllerCreateNewUserMutation,
  useUsersControllerUpdateExistingUserMutation,
  useUsersControllerSearchUsersQuery,
  useUsersControllerUpdateUserProfileMutation,
  useUsersControllerSuspendUserByUsernameMutation,
  useUsersControllerActivateUserByUsernameMutation,
  useUsersControllerAssignUserRoleMutation,
  useUsersControllerSendResetPasswordEmailMutation,
  useUsersControllerVerifyResetPasswordCredentialsQuery,
  useUsersControllerResetPasswordMutation,
  useUsersControllerChangeUserPasswordMutation,
  useUsersControllerGetUserUsageQuery,
  useUsersControllerGetAllUsersUsageQuery,
  useUsersControllerSetUserUsageLimitMutation,
  useUsersControllerCheckUserLimitReachedQuery,
  useTasksControllerGetUserTasksWithPaginationQuery,
  useTasksControllerGetAdminTasksWithPaginationQuery,
  useTasksControllerUpdateUserTaskMutation,
  useTasksControllerDeleteTasksMutation,
  useTasksControllerGetTaskByIdForUserQuery,
  useTasksControllerDownloadTaskResultAsCsvWithApiBearerQuery,
  useTasksControllerGetUserTagsForCurrentUserQuery,
  useTasksControllerChangeTaskStateAsAdminMutation,
  useTasksControllerSaveTaskResultsInNewSchemaMutation,
  useTasksControllerUpdateObservationAndRecommendationMutation,
  useTasksControllerGetPersonsOfTaskQuery,
  useTasksControllerGetPersonFramesQuery,
  useCategoryControllerCreateCategoryMutation,
  useCategoryControllerDeleteCategoryMutation,
  useCategoryControllerUpdateCategoryMutation,
  useCategoryControllerGetAllCategoriesQuery,
  useCategoryControllerCreateSystemCategoryMutation,
  useAssessmentsControllerGetAssessmentsQuery,
  useAssessmentsControllerCreateAssessmentMutation,
  useAssessmentsControllerUpdateAssessmentMutation,
  useAssessmentsControllerDeleteAssessmentMutation,
  useRolesControllerReadPermissionsQuery,
  useRolesControllerCreateRoleMutation,
  useRolesControllerUpdateRoleMutation,
  useRolesControllerFindRoleQuery,
  useRolesControllerDeleteRoleMutation,
  useRolesControllerReadRoleQuery,
  useMediaControllerGetMediaUrlQuery,
  useMediaControllerGetVideoStreamQuery,
  useMediaControllerGetPoseUrlQuery,
  useMediaControllerUploadVideoMutation,
} = injectedRtkApi;
