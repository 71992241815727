import { FC, useContext, useMemo } from "react";
import { Container, Box, Typography, Grid } from "@mui/material";
import { FloatingCard } from "src/components/molecules/assessment/FloatingCard";
import { ScoreBoard } from "./ScoreBoard";
import { calculateEraScore } from "src/app/logic/assessment/era/score";
import { generateEraReport } from "src/app/logic/assessment/era/report";
import { EraBodyParts } from "./body-parts/EraBodyParts";
import { JobInfo } from "./JobInfo";
import { EraDto } from "src/app/services/generatedApi";
import { AssessmentContext } from "src/context/AssessmentContext";
import { Accelerators } from "./Accelerators";
import { updateNestedValue } from "./utils";

type ERAPageProps = {
  fileName?: string;
  havePose: boolean;
};

export const ERAPage: FC<ERAPageProps> = ({ fileName, havePose }) => {
  const { assessmentDataDto, setAssessmentDataDto } =
    useContext(AssessmentContext);

  const scores = useMemo(() => {
    if (!assessmentDataDto?.era) return null;
    return calculateEraScore(assessmentDataDto.era);
  }, [assessmentDataDto?.era]);

  const updateEra = (newEra: EraDto) => {
    setAssessmentDataDto({ era: newEra });
  };

  const handleUpdate = (path: string[], key: string, value: boolean) => {
    const newEra = updateNestedValue(era, path, key, value);
    updateEra(newEra);
  };

  const handleDownloadReport = () => {
    if (!assessmentDataDto?.era) return;

    const doc = generateEraReport(assessmentDataDto.era);
    const fileName = `ERA_Report.pdf`;
    doc.save(fileName);
  };

  if (!assessmentDataDto?.era) return null;

  const { era } = assessmentDataDto;

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {fileName && <FloatingCard fileName={fileName} havePose={havePose} />}

      <Typography variant="h4" gutterBottom>
        ERA Assessment
      </Typography>

      <Box mb={4}>
        <JobInfo
          jobInfo={era.jobInfo}
          onUpdate={(newJobInfo) => updateEra({ ...era, jobInfo: newJobInfo })}
        />
      </Box>

      {scores && (
        <Box mb={4}>
          <Grid container spacing={3}>
            <ScoreBoard
              scores={scores}
              onDownloadReport={handleDownloadReport}
            />
          </Grid>
        </Box>
      )}

      <EraBodyParts era={era} handleUpdate={handleUpdate} />

      <Accelerators
        accelerators={era.accelerators}
        handleUpdate={handleUpdate}
      />
    </Container>
  );
};
