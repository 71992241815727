import { FC } from "react";
import { EraDto } from "src/app/services/generatedApi";
import { BodyPart } from "./BodyPart";
import { bodyPartConfig } from "./constants";
import { Box } from "@mui/material";

interface EraBodyPartProps {
  era: EraDto;
  handleUpdate: (path: string[], key: string, value: boolean) => void;
}

export const EraBodyParts: FC<EraBodyPartProps> = ({ era, handleUpdate }) => {
  const handleChange = (
    bodyPart: string,
    side: string | null,
    section: string,
    key: string,
    value: boolean,
  ) => {
    const basePath = side ? [`${side}${bodyPart}`] : [bodyPart.toLowerCase()];

    handleUpdate([...basePath, section], key, value);
  };

  return (
    <Box>
      {Object.entries(bodyPartConfig).map(([key, config]) => (
        <BodyPart
          key={key}
          era={era}
          bodyPartKey={key}
          config={config}
          onSectionChange={handleChange}
        />
      ))}
    </Box>
  );
};
