import { FC } from "react";
import { Box, Grid } from "@mui/material";

interface ImageSectionProps {
  images: {
    src: string;
    alt: string;
    caption?: string;
  }[];
}

export const ImageSection: FC<ImageSectionProps> = ({ images }) => (
  <Box sx={{ border: "1px solid #ccc", borderRadius: 5, p: 1, mb: 1 }}>
    <Grid container spacing={2} alignItems="center" justifyContent="center">
      {images.map((image, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <img
            style={{
              width: "auto",
              height: "auto",
              maxHeight: 200,
              maxWidth: "100%",
              display: "block",
              margin: "auto",
            }}
            src={image.src}
            alt={image.alt}
          />
        </Grid>
      ))}
    </Grid>
  </Box>
);
