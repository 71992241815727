import { jsPDF } from "jspdf";
import { calculateEraScore } from "../score";

export const generateOverallScore = (
  doc: jsPDF,
  scores: ReturnType<typeof calculateEraScore>,
) => {
  const overallScore = [
    [
      {
        content: `Overall = ${scores.totalScore}`,
        styles: { fillColor: [255, 255, 0] },
      },
      "0 - 20 = Low",
      "21 - 35 = Moderate",
      "36 - 55 = High",
      "56 - 80 = Very High",
    ],
  ];

  doc.autoTable({
    startY: doc.lastAutoTable.finalY + 5,
    body: overallScore,
    theme: "grid",
    styles: {
      fontSize: 8,
      cellPadding: 2,
      halign: "center",
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
    },
  });

  return doc;
};
