import {
  AcceleratorDto,
  BackAssessmentDto,
  ElbowAssessmentDto,
  EraDto,
  NeckAssessmentDto,
  ShoulderAssessmentDto,
  WristAssessmentDto,
} from "src/app/services/generatedApi";

export function calculateEraScore(assessment: EraDto): {
  backScore: number;
  neckScore: number;
  leftShoulderScore: number;
  rightShoulderScore: number;
  leftElbowScore: number;
  rightElbowScore: number;
  leftWristScore: number;
  rightWristScore: number;
  acceleratorScore: number;
  totalScore: number;
  riskLevel: string;
} {
  const backScore = calculateBackScore(assessment.back);

  const neckScore = calculateNeckScore(assessment.neck);

  const leftShoulderScore = calculateShoulderScore(assessment.leftShoulder);
  const rightShoulderScore = calculateShoulderScore(assessment.rightShoulder);

  const leftElbowScore = calculateElbowScore(assessment.leftElbow);
  const rightElbowScore = calculateElbowScore(assessment.rightElbow);

  const leftWristScore = calculateWristScore(assessment.leftWrist);
  const rightWristScore = calculateWristScore(assessment.rightWrist);

  const acceleratorScore = calculateAcceleratorScore(assessment.accelerators);

  const totalScore =
    backScore +
    neckScore +
    (leftShoulderScore + rightShoulderScore) +
    (leftElbowScore + rightElbowScore) +
    (leftWristScore + rightWristScore) +
    acceleratorScore;

  const riskLevel = determineRiskLevel(totalScore);

  return {
    backScore,
    neckScore,
    leftShoulderScore,
    rightShoulderScore,
    leftElbowScore,
    rightElbowScore,
    leftWristScore,
    rightWristScore,
    acceleratorScore,
    totalScore,
    riskLevel,
  };
}

function calculateBackScore(back: BackAssessmentDto): number {
  let score = 0;

  if (back.posture.flexedOver20) score += 1;
  if (back.posture.extended) score += 1;
  if (back.posture.sidewaysOver20) score += 1;
  if (back.posture.twistedOver20) score += 1;

  if (back.severePosture.flexedOver45) score += 1;
  if (back.severePosture.extendedOver20) score += 1;

  if (back.force.over10lb) score += 2;

  if (back.repetition.over2PerMin) score += 2;

  if (back.staticExertion?.over10Sec) score += 1;

  return score;
}

function calculateNeckScore(neck: NeckAssessmentDto): number {
  let score = 0;

  if (neck.posture.flexedOver30) score += 1;
  if (neck.posture.extended) score += 1;
  if (neck.posture.sideways) score += 1;
  if (neck.posture.twisted) score += 1;

  if (neck.severePosture.flexedOver60) score += 1;
  if (neck.severePosture.extendedOver30) score += 1;

  if (neck.repetition.over3PerMin) score += 2;

  if (neck.staticExertion?.over10Sec) score += 1;

  return score;
}

function calculateShoulderScore(shoulder: ShoulderAssessmentDto): number {
  let score = 0;

  if (shoulder.posture.flexedOver45) score += 1;
  if (shoulder.posture.extended) score += 1;
  if (shoulder.posture.abductedOver45) score += 1;

  if (shoulder.severePosture.flexedOver90) score += 1;
  if (shoulder.severePosture.abductedOver90) score += 1;

  if (shoulder.force.over10lb1Hand) score += 2;
  if (shoulder.force.over15lb2Hand) score += 2;

  if (shoulder.repetition.over2Point5PerMin) score += 2;

  if (shoulder.staticExertion?.over10Sec) score += 1;

  return score;
}

function calculateElbowScore(elbow: ElbowAssessmentDto): number {
  let score = 0;

  if (elbow.posture.flexedUnder60) score += 1;
  if (elbow.posture.flexedOver100) score += 1;
  if (elbow.posture.forearmRotated) score += 1;

  if (elbow.severePosture.forearmRotatedOver60) score += 1;

  if (elbow.force.over10lb1Hand) score += 2;
  if (elbow.force.over15lb2Hand) score += 2;

  if (elbow.repetition.over10PerMin) score += 2;

  if (elbow.staticExertion?.over10Sec) score += 1;

  return score;
}

function calculateWristScore(wrist: WristAssessmentDto): number {
  let score = 0;

  if (wrist.posture.flexedOver45) score += 1;
  if (wrist.posture.extendedOver45) score += 1;
  if (wrist.posture.radialDeviatedOver15) score += 1;
  if (wrist.posture.ulnarDeviatedOver20) score += 1;

  if (wrist.severePosture.flexedOver65) score += 1;
  if (wrist.severePosture.extendedOver65) score += 1;

  if (wrist.force.pinchGripOver2lb) score += 2;
  if (wrist.force.powerGripOver10lb) score += 2;

  if (wrist.repetition.over15PerMin) score += 2;

  if (wrist.staticExertion?.over10Sec) score += 1;

  return score;
}

function calculateAcceleratorScore(accelerators: AcceleratorDto): number {
  let score = 0;

  if (accelerators.vibration) score += 1;
  if (accelerators.contactStress) score += 1;
  if (accelerators.impactStress) score += 1;

  return score;
}

function determineRiskLevel(totalScore: number): string {
  if (totalScore <= 20) return "Low";
  if (totalScore <= 35) return "Moderate";
  if (totalScore <= 55) return "High";
  return "Very High";
}
