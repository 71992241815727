import React, { FC, useContext, useMemo } from "react";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { AssessmentContext } from "src/context/AssessmentContext";
import { TaskContext } from "src/context/TaskContext";
import { AssessmentsChart } from "../../charts/lineChart/typeTwo/AssessmentsChart";
import { AssessmentPage } from "./AssessmentPage";
import { useAssessmentLogic } from "./useAssessmentLogic";

interface AssessmentsProps {
  fileName: string;
  havePose: boolean;
}

export const Assessments: FC<AssessmentsProps> = ({ fileName, havePose }) => {
  const {
    frameIndex,
    setAssessmentDataDto,
    assessmentPageIndex,
    setAssessmentPageIndex,
    selectedMethod,
    setTargetId,
  } = useContext(AssessmentContext);

  const { selectedAngles, selectedAngles3D, fps, selectedPersonId, isSited } =
    useContext(TaskContext);

  const { getChartData, handleUseSelectedFrame } = useAssessmentLogic({
    selectedMethod,
    selectedAngles,
    selectedAngles3D,
    fps,
    isSited,
    frameIndex,
    setAssessmentDataDto,
    setAssessmentPageIndex,
    setTargetId,
  });

  const chartData = useMemo(() => getChartData(), [getChartData]);

  const pid = useMemo(() => {
    if (selectedPersonId === undefined || !frameIndex) return undefined;
    return typeof selectedPersonId === "number"
      ? selectedPersonId
      : selectedPersonId[frameIndex];
  }, [selectedPersonId, frameIndex]);

  if (assessmentPageIndex !== 1) {
    return (
      <Paper
        sx={{
          width: "100%",
          pt: 2,
          px: 2,
          pb: 1,
          borderRadius: { xs: 0, md: 1 },
        }}
      >
        <AssessmentPage fileName={fileName} havePose={havePose} />
      </Paper>
    );
  }

  return (
    <Paper
      sx={{
        width: "100%",
        pt: 2,
        px: 2,
        pb: 1,
        borderRadius: { xs: 0, md: 1 },
      }}
    >
      <Stack spacing={3}>
        <Typography variant="h5">
          {selectedMethod === "ERA"
            ? "Please select a frame to represent the task"
            : "Please Select Assessment Frame"}
        </Typography>
        <Box sx={{ height: "300px", mt: 3 }}>
          <AssessmentsChart
            assessmentAlgorithm={selectedMethod || "REBA"}
            data={chartData}
          />
        </Box>
        <Stack justifyContent="end" direction="row">
          <Button
            disabled={!frameIndex}
            variant="contained"
            onClick={() => handleUseSelectedFrame(pid)}
          >
            Use Selected Frame
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};
