import { FC, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Stack,
  Grid,
  TextField,
  Box,
} from "@mui/material";
import { ExpandMore, Work } from "@mui/icons-material";
import { EraJobInfoDto } from "src/app/services/generatedApi";

interface JobInfoProps {
  jobInfo?: EraJobInfoDto;
  onUpdate: (newJobInfo: EraJobInfoDto) => void;
}

export const JobInfo: FC<JobInfoProps> = ({ jobInfo, onUpdate }) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  const handleInputChange =
    (field: keyof EraJobInfoDto) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value =
        field === "cycleTime"
          ? Math.max(0, Number(event.target.value) || 0)
          : event.target.value;

      onUpdate({
        ...jobInfo,
        [field]: value,
      });
    };

  const getDisplayValue = (value?: string | number | Date) => {
    if (value === null || value === undefined) return "";
    if (value instanceof Date) return value.toISOString().split("T")[0];
    return value;
  };

  return (
    <Accordion expanded={expanded} onChange={handleAccordionChange}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          borderBottom: expanded ? "1px solid" : "none",
          borderColor: "divider",
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Work color="primary" />
          <Box>
            <Typography variant="h6">
              {jobInfo?.jobName || "New Job Assessment"}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {jobInfo?.department ? `${jobInfo?.department} • ` : ""}
              {jobInfo?.shift
                ? `${jobInfo?.shift} Shift`
                : "No shift specified"}
            </Typography>
          </Box>
        </Stack>
      </AccordionSummary>

      <AccordionDetails sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Job Name"
              value={getDisplayValue(jobInfo?.jobName)}
              onChange={handleInputChange("jobName")}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Department"
              value={getDisplayValue(jobInfo?.department)}
              onChange={handleInputChange("department")}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Assessor"
              value={getDisplayValue(jobInfo?.assessor)}
              onChange={handleInputChange("assessor")}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Shift"
              value={getDisplayValue(jobInfo?.shift)}
              onChange={handleInputChange("shift")}
              size="small"
              placeholder=""
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Date"
              type="date"
              value={getDisplayValue(jobInfo?.date)}
              onChange={handleInputChange("date")}
              size="small"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Cycle Time (seconds)"
              type="number"
              value={getDisplayValue(jobInfo?.cycleTime)}
              onChange={handleInputChange("cycleTime")}
              size="small"
              InputProps={{
                inputProps: {
                  min: 0,
                  step: 1,
                },
              }}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
