import { jsPDF } from "jspdf";

export const generateRiskRating = (doc: jsPDF) => {
  const riskBody = [
    [
      { content: "0 - 2", styles: { fillColor: [144, 238, 144] } },
      "Low",
      "No action required, monitor job",
    ],
    [
      { content: "3 - 5", styles: { fillColor: [255, 255, 0] } },
      "Moderate",
      "Further investigation and action recommended",
    ],
    [
      { content: "6 - 8", styles: { fillColor: [255, 165, 0] } },
      "High",
      "Action strongly recommended",
    ],
    [
      { content: "9 - 10", styles: { fillColor: [255, 0, 0] } },
      "Very High",
      "Immediate action strongly recommended",
    ],
  ];

  doc.autoTable({
    startY: doc.lastAutoTable.finalY + 10,
    head: [["Score", "Risk Rating", "Action Level"]],
    body: riskBody,
    theme: "grid",
    styles: {
      fontSize: 8,
      cellPadding: 2,
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
    },
    headStyles: {
      fillColor: [220, 220, 220],
      textColor: [0, 0, 0],
      fontStyle: "bold",
    },
  });

  return doc;
};
