import { EraDto } from "src/app/services/generatedApi";

export const defaultEraData: EraDto = {
  jobInfo: {
    jobName: undefined,
    department: undefined,
    assessor: undefined,
    shift: undefined,
    date: undefined,
    cycleTime: undefined,
  },
  back: {
    posture: {
      flexedOver20: false,
      extended: false,
      sidewaysOver20: false,
      twistedOver20: false,
    },
    severePosture: {
      flexedOver45: false,
      extendedOver20: false,
    },
    force: {
      over10lb: false,
    },
    repetition: {
      over2PerMin: false,
    },
    staticExertion: { over10Sec: false },
  },
  neck: {
    posture: {
      flexedOver30: false,
      extended: false,
      sideways: false,
      twisted: false,
    },
    severePosture: {
      flexedOver60: false,
      extendedOver30: false,
    },
    repetition: {
      over3PerMin: false,
    },
    staticExertion: { over10Sec: false },
  },
  leftShoulder: {
    posture: {
      flexedOver45: false,
      extended: false,
      abductedOver45: false,
    },
    severePosture: {
      flexedOver90: false,
      abductedOver90: false,
    },
    force: {
      over10lb1Hand: false,
      over15lb2Hand: false,
    },
    repetition: {
      over2Point5PerMin: false,
    },
    staticExertion: { over10Sec: false },
  },
  rightShoulder: {
    posture: {
      flexedOver45: false,
      extended: false,
      abductedOver45: false,
    },
    severePosture: {
      flexedOver90: false,
      abductedOver90: false,
    },
    force: {
      over10lb1Hand: false,
      over15lb2Hand: false,
    },
    repetition: {
      over2Point5PerMin: false,
    },
    staticExertion: { over10Sec: false },
  },
  leftElbow: {
    posture: {
      flexedUnder60: false,
      flexedOver100: false,
      forearmRotated: false,
    },
    severePosture: {
      forearmRotatedOver60: false,
    },
    force: {
      over10lb1Hand: false,
      over15lb2Hand: false,
    },
    repetition: {
      over10PerMin: false,
    },
    staticExertion: { over10Sec: false },
  },
  rightElbow: {
    posture: {
      flexedUnder60: false,
      flexedOver100: false,
      forearmRotated: false,
    },
    severePosture: {
      forearmRotatedOver60: false,
    },
    force: {
      over10lb1Hand: false,
      over15lb2Hand: false,
    },
    repetition: {
      over10PerMin: false,
    },
    staticExertion: { over10Sec: false },
  },
  leftWrist: {
    posture: {
      flexedOver45: false,
      extendedOver45: false,
      radialDeviatedOver15: false,
      ulnarDeviatedOver20: false,
    },
    severePosture: {
      flexedOver65: false,
      extendedOver65: false,
    },
    force: {
      pinchGripOver2lb: false,
      powerGripOver10lb: false,
    },
    repetition: {
      over15PerMin: false,
    },
    staticExertion: { over10Sec: false },
  },
  rightWrist: {
    posture: {
      flexedOver45: false,
      extendedOver45: false,
      radialDeviatedOver15: false,
      ulnarDeviatedOver20: false,
    },
    severePosture: {
      flexedOver65: false,
      extendedOver65: false,
    },
    force: {
      pinchGripOver2lb: false,
      powerGripOver10lb: false,
    },
    repetition: {
      over15PerMin: false,
    },
    staticExertion: { over10Sec: false },
  },
  accelerators: {
    vibration: undefined,
    contactStress: undefined,
    impactStress: undefined,
  },
};
