import { EraDto } from "src/app/services/generatedApi";

export const updateNestedValue = (
  era: EraDto,
  path: string[],
  key: string,
  value: boolean,
): EraDto => {
  const newEra = JSON.parse(JSON.stringify(era));

  let current: any = newEra;
  for (let i = 0; i < path.length - 1; i++) {
    current = current[path[i]];
  }

  current[path[path.length - 1]][key] = value;
  return newEra;
};
