import { FC } from "react";
import { Box, Typography, Divider, Stack } from "@mui/material";
import { ImageSection } from "./ImageSection";
import { EraDto } from "src/app/services/generatedApi";
import { BodyPartConfig, sectionColors } from "./constants";
import { QuestionType } from "src/app/logic/assessment/reba-rula/types";
import { SelectItem } from "src/components/atoms/SelectItem";

interface BodyPartProps {
  era: EraDto;
  bodyPartKey: string;
  config: BodyPartConfig;
  onSectionChange: (
    bodyPart: string,
    side: string | null,
    section: string,
    key: string,
    value: boolean,
  ) => void;
}

const RotatedTitle: FC<{
  children: React.ReactNode;
  backgroundColor: string;
}> = ({ children, backgroundColor }) => (
  <Box
    sx={{
      width: 45,
      writingMode: "vertical-rl",
      transform: "rotate(180deg)",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      py: 2,
      background: backgroundColor,
      color: "white",
    }}
  >
    <Typography sx={{ fontSize: 13, fontWeight: 800 }}>{children}</Typography>
  </Box>
);

export const BodyPart: FC<BodyPartProps> = ({
  era,
  bodyPartKey,
  config,
  onSectionChange,
}) => {
  const { title, images, sections, bilateral } = config;

  const renderSections = (side: string | null = null) => (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        justifyContent: "space-between",
        rowGap: 1,
      }}
    >
      {Object.entries(sections).map(
        ([sectionKey, sectionConfig]: [string, any]) => {
          const colors =
            sectionColors[sectionKey as keyof typeof sectionColors];

          const sectionData = side
            ? (era as any)[
                `${side}${bodyPartKey.charAt(0).toUpperCase() + bodyPartKey.slice(1)}` as keyof EraDto
              ][sectionKey as keyof typeof era.back]
            : (era as any)[bodyPartKey as keyof EraDto][
                sectionKey as keyof typeof era.back
              ];

          return (
            <Stack
              key={sectionKey}
              direction="row"
              sx={{
                borderRadius: 1,
                height: "auto",
                minHeight: 80,
                flexBasis:
                  !sectionConfig.title.includes("Postures") &&
                  Object.keys(sectionConfig.labels).length === 1
                    ? title.includes("Back")
                      ? "32%"
                      : "49%"
                    : "100%",
                border: 2,
                borderColor: colors.border,
                borderWidth: 2,
                overflow: "hidden",
              }}
            >
              <RotatedTitle backgroundColor={colors.title}>
                {sectionConfig.title}
              </RotatedTitle>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 1,
                  alignItems: "center",
                  width: "100%",
                  p: 1,
                }}
              >
                {Object.entries(sectionData).map(([key, value]) => (
                  <Box key={key} sx={{ flexGrow: 1 }}>
                    <SelectItem
                      type={QuestionType.CHECK}
                      text={sectionConfig.labels[key] || key}
                      isSelected={value as boolean}
                      onSelect={() =>
                        onSectionChange(
                          bodyPartKey.charAt(0).toUpperCase() +
                            bodyPartKey.slice(1),
                          side,
                          sectionKey,
                          key,
                          !(value as boolean),
                        )
                      }
                    />
                  </Box>
                ))}
              </Box>
            </Stack>
          );
        },
      )}
    </Box>
  );

  return (
    <Stack spacing={2} sx={{ my: 3 }}>
      <Typography variant="h5">{title}</Typography>
      <ImageSection images={images} />
      {bilateral ? (
        <Stack
          direction={{ xs: "column", sm: "row" }}
          divider={
            <Divider orientation="vertical" flexItem sx={{ borderWidth: 2 }} />
          }
          spacing={2}
          sx={{ width: "100%" }}
        >
          {["left", "right"].map((side) => (
            <Box key={side} sx={{ flexGrow: 1 }}>
              <Typography variant="subtitle1" gutterBottom>
                {side.charAt(0).toUpperCase() + side.slice(1)}{" "}
                {bodyPartKey.charAt(0).toUpperCase() + bodyPartKey.slice(1)}
              </Typography>
              {renderSections(side)}
            </Box>
          ))}
        </Stack>
      ) : (
        renderSections()
      )}
    </Stack>
  );
};
