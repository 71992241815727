import { FC, useContext, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Grow,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { SaveDialog } from "./SaveDialog";
import { AssessmentContext } from "src/context/AssessmentContext";
import { VideoPlayer } from "src/components/molecules/result/VideoPlayer/VideoPlayer";
import { getRebaScore } from "src/app/logic/assessment/reba/Reba";
import { getRulaScore } from "src/app/logic/assessment/rula/Rula";
import { getRebaScoresDetailItem } from "src/components/organisms/assessment/help/rebaScoresDetail";
import { getRulaScoresDetailItem } from "src/components/organisms/assessment/help/rulaScoresDetail";
import { getEraScoresDetailItem } from "src/components/organisms/assessment/help/eraScoresDetail";
import { OpenInFull, CloseFullscreen } from "@mui/icons-material";
import { calculateEraScore } from "src/app/logic/assessment/era/score";

type FloatingCardProps = { fileName: string; havePose: boolean };

export const FloatingCard: FC<FloatingCardProps> = ({ fileName, havePose }) => {
  const {
    id,
    assessmentDataDto,
    setHelpMethod,
    frameIndex,
    targetId,
    selectedMethod,
  } = useContext(AssessmentContext);

  const [offset, setOffset] = useState(0);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.addEventListener("scroll", onScroll, { passive: true });

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const score = useMemo(() => {
    if (selectedMethod === "REBA" && assessmentDataDto?.reba) {
      return getRebaScore(assessmentDataDto.reba);
    }
    if (selectedMethod === "RULA" && assessmentDataDto?.rula) {
      return getRulaScore(assessmentDataDto.rula);
    }
    if (selectedMethod === "ERA" && assessmentDataDto?.era) {
      return calculateEraScore(assessmentDataDto.era).totalScore;
    }
    return 0;
  }, [assessmentDataDto, selectedMethod]);

  const detailItem = useMemo(() => {
    if (selectedMethod === "REBA") {
      return getRebaScoresDetailItem(score);
    }
    if (selectedMethod === "RULA") {
      return getRulaScoresDetailItem(score);
    }
    if (selectedMethod === "ERA") {
      return getEraScoresDetailItem(score);
    }
    return { detail: "No data available", color: "#ccc", icon: null };
  }, [score, selectedMethod]);

  const IconComponent = detailItem.icon;

  return (
    <>
      <Grow
        in={offset > 400}
        style={{ transformOrigin: "0 0 0" }}
        timeout={500}
      >
        <Stack
          sx={{
            position: "fixed",
            top: isMinimized ? 115 : 85,
            right: 20,
            maxWidth: { xs: "50%", sm: "30%", md: "20%" },
            zIndex: 10,
            background: "#fefefedd",
            borderRadius: 1,
            border: "1px solid #ccc",
            maxHeight: { xs: "80%", sm: "60%" },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: isMinimized ? 0 : 10,
              left: isMinimized ? 0 : 10,
              background: isMinimized ? "#efefef" : "transparent",
              borderRadius: isMinimized ? "50% 50% 0 0" : 0,
              transform: isMinimized ? "translateY(-100%)" : "",
              zIndex: 10,
              border: isMinimized ? "1px solid #ccc" : "",
            }}
          >
            <Tooltip title={isMinimized ? "Maximize" : "Minimize"}>
              <IconButton onClick={() => setIsMinimized(!isMinimized)}>
                {isMinimized ? <OpenInFull /> : <CloseFullscreen />}
              </IconButton>
            </Tooltip>
          </Box>
          {!isMinimized && (
            <VideoPlayer
              fileName={fileName}
              controls={false}
              havePose={havePose}
              selectedFrameIndex={frameIndex}
              selectedPersonId={targetId}
              radius={false}
            />
          )}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            p={1}
          >
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Typography sx={{ fontWeight: 600 }}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    display: { xs: "none", md: "inline" },
                  }}
                >
                  Total{" "}
                </Typography>
                Score: {score}
              </Typography>
              <Tooltip title={detailItem.detail}>
                <IconButton
                  sx={{ color: detailItem.color }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setHelpMethod(selectedMethod);
                  }}
                  size="small"
                >
                  {IconComponent && <IconComponent fontSize="small" />}
                </IconButton>
              </Tooltip>
            </Stack>
            <Button variant="contained" onClick={() => setOpenSaveDialog(true)}>
              {id ? "Update" : "Save"}
            </Button>
          </Stack>
        </Stack>
      </Grow>
      <SaveDialog
        open={openSaveDialog}
        handleClose={() => setOpenSaveDialog(false)}
      />
    </>
  );
};
