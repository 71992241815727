import rula0 from "src/assets/images/rula/rula0.png";
import rula1 from "src/assets/images/rula/rula1.png";
import rula2 from "src/assets/images/rula/rula2.png";
import rula3 from "src/assets/images/rula/rula3.png";
import rula4 from "src/assets/images/rula/rula4.png";
import rula5 from "src/assets/images/rula/rula5.png";
import rula6 from "src/assets/images/rula/rula6.png";
import rula7 from "src/assets/images/rula/rula7.png";
import rula8 from "src/assets/images/rula/rula8.png";
import rula9 from "src/assets/images/rula/rula9.png";
import rula11 from "src/assets/images/rula/rula11.png";
import rula12 from "src/assets/images/rula/rula12.png";
import rula13 from "src/assets/images/rula/rula13.png";
import rula14 from "src/assets/images/rula/rula14.png";
import rula15 from "src/assets/images/rula/rula15.png";
import rula20 from "src/assets/images/rula/rula20.png";
import rula21 from "src/assets/images/rula/rula21.png";
import rula22 from "src/assets/images/rula/rula22.png";

import { QuestionType } from "../reba-rula/types";
import {
  RulaForceMode,
  RulaItems,
  RulaLowerArm,
  RulaNeck,
  RulaQuestions,
  RulaTrunk,
  RulaUpperArm,
  RulaWrist,
} from "./types";
import { Angle3D } from "src/types/pose";
import { deepCloneQuestions } from "../utils";

const rulaQuestions: RulaQuestions = {
  [RulaItems.UPPER_ARM_ANGLE]: {
    type: QuestionType.SELECT,
    question: "Upper Arm Position",
    selectedIndex: 0,
    items: [
      {
        img: rula4,
        text: "Upper arm -20 to 20",
        id: RulaUpperArm.ANGLE__20_20,
        value: 1,
      },
      {
        img: rula5,
        text: "Upper Arm -20 minus",
        id: RulaUpperArm.ANGLE__20_MINUS,
        value: 2,
      },
      {
        img: rula6,
        text: "Upper arm 20 to 45",
        id: RulaUpperArm.ANGLE_20_45,
        value: 2,
      },
      {
        img: rula7,
        text: "Upper arm 45 to 90",
        id: RulaUpperArm.ANGLE_45_90,
        value: 3,
      },
      {
        img: rula8,
        text: "Upper arm 90 plus",
        id: RulaUpperArm.ANGLE_90_PLUS,
        value: 4,
      },
    ],
    update(angle: number) {
      this.selectedIndex =
        angle < -20 ? 1 : angle < 20 ? 0 : angle < 45 ? 2 : angle < 90 ? 3 : 4;
    },
  },
  [RulaItems.UPPER_ARM_ADJUST]: {
    type: QuestionType.CHECK,
    items: [
      {
        text: "Shoulder is raised: +1",
        selected: false,
        value: 1,
      },
      {
        text: "Upper arm is abducted: +1",
        selected: false,
        value: 1,
      },
      {
        text: "Arm is supported or person is leaning: -1",
        selected: false,
        value: -1,
      },
    ],
  },
  [RulaItems.LOWER_ARM_ANGLE]: {
    type: QuestionType.SELECT,
    question: "Lower Arm Position",
    selectedIndex: 0,
    items: [
      {
        img: rula14,
        text: "Lower arm 60 to 100: +1",
        id: RulaLowerArm.ANGLE_60_100,
        value: 1,
      },
      {
        img: rula15,
        text: "Lower arm 0 to 60 or 100 plus: +2",
        id: RulaLowerArm.ANGLE_0_60_OR_100_PLUS,
        value: 2,
      },
    ],
    update(angle: number) {
      this.selectedIndex = angle < 100 && angle > 60 ? 0 : 1;
    },
  },
  [RulaItems.LOWER_ARM_ADJUST]: {
    type: QuestionType.CHECK,
    items: [
      {
        text: "Either arm is working across midline or out to side of body: +1",
        selected: false,
        value: 1,
      },
    ],
  },
  [RulaItems.WRIST_ANGLE]: {
    type: QuestionType.SELECT,
    question: "Wrist Position",
    selectedIndex: 0,
    items: [
      {
        img: rula22,
        text: "Wrist 0",
        id: RulaWrist.ANGLE_0,
        value: 1,
      },
      {
        img: rula20,
        text: "Wrist -15 to 15",
        id: RulaWrist.ANGLE__15_15,
        value: 2,
      },
      {
        img: rula21,
        text: "Wrist -15 minus or 15 plus",
        id: RulaWrist.ANGLE_GT_15,
        value: 3,
      },
    ],
    update(angle: number) {
      this.selectedIndex =
        Math.abs(angle) < 5 ? 0 : Math.abs(angle) < 15 ? 1 : 2;
    },
  },
  [RulaItems.BENT_WRIST]: {
    type: QuestionType.CHECK,
    items: [
      {
        text: "Wrist is bent from midline: +1",
        selected: false,
        value: 1,
      },
    ],
  },
  [RulaItems.WRIST_TWIST]: {
    type: QuestionType.SELECT,
    question: "Wrist Twist",
    selectedIndex: 0,
    items: [
      {
        text: "Wrist is twisted in mid-range: +1",
        id: false,
        value: 1,
      },
      {
        text: "Wrist is at or near end of range: +2",
        id: true,
        value: 2,
      },
    ],
    update: () => {},
  },
  [RulaItems.MUSCLE_USE]: {
    type: QuestionType.CHECK,
    question: "Muscle Use",
    items: [
      {
        text: "Posture mainly static (i.e. held>1 minute), Or action repeated occurs 4X per minute: +1",
        selected: false,
        value: 1,
      },
    ],
  },
  [RulaItems.FORCE_MODE]: {
    type: QuestionType.SELECT,
    question: "Force/Load",
    selectedIndex: 0,
    items: [
      {
        text: "Load < 4.4 lbs. (intermittent): +0",
        id: RulaForceMode.LOAD_0_4_LBS,
        value: 0,
      },
      {
        text: "Load 4.4 to 22 lbs. (intermittent): +1",
        id: RulaForceMode.LOAD_4_22_LBS,
        value: 1,
      },
      {
        text: "Load 4.4 to 22 lbs. (static or repeated): +2",
        id: RulaForceMode.LOAD_4_22_LBS_REPEATED,
        value: 2,
      },
      {
        text: "Load > 22 lbs. Or repeated or shocks: +3",
        id: RulaForceMode.LOAD_22_PLUS_LBS,
        value: 3,
      },
    ],
    update(load: number) {
      this.selectedIndex = load < 4.4 ? 0 : load < 22 ? 1 : 3;
    },
  },
  [RulaItems.NECK_ANGLE]: {
    type: QuestionType.SELECT,
    selectedIndex: 0,
    question: "Neck Position",
    items: [
      {
        img: rula0,
        text: "Neck 0 to 10",
        id: RulaNeck.ANGLE_0_10,
        value: 1,
      },
      {
        img: rula1,
        text: "Neck 10 to 20",
        id: RulaNeck.ANGLE_10_20,
        value: 2,
      },
      {
        img: rula2,
        text: "Neck 20 plus",
        id: RulaNeck.ANGLE_20_PLUS,
        value: 3,
      },
      {
        img: rula3,
        text: "Neck 0 minus",
        id: RulaNeck.ANGLE_0_MINUS,
        value: 4,
      },
    ],
    update(angle: number) {
      this.selectedIndex = angle < 0 ? 3 : angle < 10 ? 0 : angle < 20 ? 1 : 2;
    },
  },
  [RulaItems.NECK_ADJUST]: {
    type: QuestionType.CHECK,
    items: [
      {
        text: "Neck is twisted: +1",
        selected: false,
        value: 1,
      },
      {
        text: "Neck is side bending: +1",
        selected: false,
        value: 1,
      },
    ],
  },
  [RulaItems.TRUNK_ANGLE]: {
    type: QuestionType.SELECT,
    question: "Trunk Position",
    selectedIndex: 1,
    items: [
      {
        img: rula9,
        text: "trunk 0",
        id: RulaTrunk.ANGLE_0_10,
        value: 1,
      },
      {
        img: rula11,
        text: "trunk 0 to 20",
        id: RulaTrunk.ANGLE_10_20,
        value: 2,
      },
      {
        img: rula12,
        text: "trunk 20 to 60",
        id: RulaTrunk.ANGLE_20_60,
        value: 3,
      },
      {
        img: rula13,
        text: "trunk 60 plus",
        id: RulaTrunk.ANGLE_60_PLUS,
        value: 4,
      },
    ],
    update(angle: number, isSited: boolean = false, selectedAngle3D?: Angle3D) {
      let betaAngle = selectedAngle3D?.Beta || angle;

      if (isSited) betaAngle -= 90;
      if (Number.isNaN(betaAngle)) betaAngle = 0;
      this.selectedIndex =
        betaAngle < 5 ? 0 : betaAngle < 20 ? 1 : betaAngle < 60 ? 2 : 3;
    },
  },
  [RulaItems.TRUNK_ADJUST]: {
    type: QuestionType.CHECK,
    items: [
      {
        text: "Trunk is twisted: +1",
        selected: false,
        value: 1,
      },
      {
        text: "Trunk is side bending: +1",
        selected: false,
        value: 1,
      },
    ],
    update(selectedAngle3D?: Angle3D) {
      if (!selectedAngle3D?.Beta || !selectedAngle3D?.Alpha) {
        return;
      }
      if (
        Math.abs(selectedAngle3D.Alpha) - Math.abs(selectedAngle3D.Beta) >
        10
      ) {
        this.items[0].selected = false;
        this.items[1].selected = true;
      } else {
        this.items[0].selected = false;
        this.items[1].selected = false;
      }
    },
  },
  [RulaItems.LEG_SUPPORTED]: {
    type: QuestionType.SELECT,
    question: "Legs",
    selectedIndex: 0,
    items: [
      {
        text: "Legs and feet are supported: +1",
        id: false,
        value: 1,
      },
      {
        text: "Not supported: +2",
        id: true,
        value: 2,
      },
    ],
    update() {},
  },
};

export const getRulaQuestions = (): RulaQuestions =>
  deepCloneQuestions(rulaQuestions);
