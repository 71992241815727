import { jsPDF } from "jspdf";
import { EraDto } from "src/app/services/generatedApi";

export const generateAccelerators = (doc: jsPDF, eraDto: EraDto) => {
  doc.text(
    "Step 2: Identify accelerators to WMSDs",
    10,
    doc.lastAutoTable.finalY + 10,
  );

  const acceleratorsHead = [
    ["Accelerators:", "Y/N:", "Body Part Affected:", "Task:", "Score"],
  ];
  const acceleratorsBody = [
    [
      "Vibration",
      eraDto.accelerators?.vibration ? "Y" : "N",
      eraDto.accelerators?.vibration?.bodyPartAffected || "",
      eraDto.accelerators?.vibration?.task || "",
      eraDto.accelerators?.vibration ? "1" : "",
    ],
    [
      "Contact Stress",
      eraDto.accelerators?.contactStress ? "Y" : "N",
      eraDto.accelerators?.contactStress?.bodyPartAffected || "",
      eraDto.accelerators?.contactStress?.task || "",
      eraDto.accelerators?.contactStress ? "1" : "",
    ],
    [
      "Impact Stress",
      eraDto.accelerators?.impactStress ? "Y" : "N",
      eraDto.accelerators?.impactStress?.bodyPartAffected || "",
      eraDto.accelerators?.impactStress?.task || "",
      eraDto.accelerators?.impactStress ? "1" : "",
    ],
  ];

  doc.autoTable({
    startY: doc.lastAutoTable.finalY + 15,
    head: acceleratorsHead,
    body: acceleratorsBody,
    theme: "grid",
    styles: {
      fontSize: 8,
      cellPadding: 2,
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
    },
    headStyles: {
      fillColor: [220, 220, 220],
      textColor: [0, 0, 0],
      fontStyle: "bold",
    },
  });

  return doc;
};
